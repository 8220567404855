import { useDispatch, useSelector } from 'react-redux';
import { userLogin, userLogout } from './LoginReducer';
import { useEffect, useState } from 'react';
import { closeSideBar } from '../../sidebar/sidebarReducer';
import useLogin from './useLogin';
import LoginEmailInput from './LoginEmailInput';
import OutlineLabelField from '../../form/OutlineLabelField';
import Popup from '@/components/popup/Popup';
import Link2 from '@/components/common/transition/Link2';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import useSaveLoginId from '@/components/user/login/useSaveLoginId';
import useDebugLog from '@/components/debug/useDebugLog';
import useEasyLogin from '@/components/easyLogin/useEasyLogin';

export default function LoginForm({ email, onFormChange }) {
  const dispatch = useDispatch();
  const { save, load, remove, isSaved } = useSaveLoginId();
  const [useSaveId, setUseSaveId] = useState(isSaved);
  const { isLoggedIn, code, errorMessage } = useLogin();
  const navigator = useNavigate2();
  const [popup, setPopup] = useState(null);
  const { isRegistered, tryEasyLogin } = useEasyLogin();
  const { sendLog } = useDebugLog();
  const isOpen = useSelector((state) => state.sidebarReducer.isLoginOpen);

  const tryFormEasyLogin = () => {
    if (!isLoggedIn && isRegistered) {
      tryEasyLogin({ ignoreCancel: true })
        .then((res) => {
          sendLog(`LoginForm bioLogin ${res}`);
        })
        .catch((e) => {
          sendLog(`LoginForm bioLogin error ${e.message}`);
        });
    }
  };

  useEffect(() => {
    if (isOpen) {
      tryFormEasyLogin();
    }
  }, [isOpen]);

  useEffect(() => {
    if (code === '00') {
      close();
    } else if (code === '11') {
      dispatch(userLogout());
      setPopup({
        title: '가입하지 않은 아이디입니다.',
        message: '회원가입을 진행해주세요.',
        okMessage: '등록하기',
        okCallback: () => {
          dispatch(userLogout());
          setPopup(null);
          close();
          navigator('/join');
        },
        cancelCallback: () => {
          setPopup(null);
        },
      });
    } else if (code === '12') {
      const isResign = errorMessage?.includes('탈퇴');
      const isSleep = errorMessage?.includes('휴면');
      const isDeny = errorMessage?.includes('심사거부');
      const isWait = errorMessage?.includes('심사');
      const isWait2 = errorMessage?.includes('대기');
      let reason = '';
      if (isResign) {
        reason = '탈퇴';
      } else if (isSleep) {
        reason = '휴면';
      } else if (isDeny) {
        reason = '심사거부';
      } else if (isWait) {
        reason = '심사중';
      } else if (isWait2) {
        reason = '대기';
      } else {
        reason = '알수없음';
      }

      setPopup({
        title: '로그인 실패',
        message: `현재 계정 상태가 ${reason} 이므로\n로그인 할 수 없습니다.`,
        okCallback: () => {
          setPopup(null);
          dispatch(userLogout());
        },
      });
    } else if (code === '31') {
      setPopup({
        title: '비밀번호 재설정 필요',
        message: '보안 강화를 위해 비밀번호를 재설정 해주세요.',
        okCallback: () => {
          setPopup(null);
          dispatch(userLogout());
          onFormChange(`password?email=${loginState.id}`);
        },
      });
    } else if (code === '15') {
      close();
      setPopup(null);
      dispatch(userLogout());
      navigator(`/join/emailAuth/${loginState.id}`);
    } else if (code != null) {
      setPopup({
        title: '로그인 실패',
        message: errorMessage?.replace('FAIL - ', ''),
        okCallback: () => {
          setPopup(null);
          dispatch(userLogout());
        },
      });
    }
  }, [code]);

  const [loginState, setLoginState] = useState(async () => ({
    id: '',
    pw: '',
  }));

  useEffect(() => {
    load().then((savedId) => {
      if (savedId != null) {
        setLoginState({
          id: savedId,
          pw: '',
        });
      }
    });
  }, []);

  useEffect(() => {
    if (email != null && email !== '') {
      setLoginState((p) => ({
        ...p,
        id: email,
      }));
    }
  }, [email]);

  const handleLogin = async () => {
    if (useSaveId) {
      await save(loginState.id);
    } else {
      await remove();
    }
    let data = {
      ...loginState,
    };
    // data.pw = encrypt(data.pw);
    dispatch(userLogin(data));
  };

  const onIdKeyDown = (e) => {
    if (e.key === 'Enter') {
      document.querySelector('#login_pw').focus();
    }
  };

  const onPwKeyEnter = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const close = () => {
    dispatch(closeSideBar());
  };

  return (
    <>
      <div className="login_slide sidebar open">
        <p className="flex flex-row-reverse">
          <img
            src="/images/login_close.png"
            alt="닫기"
            className="close"
            onClick={close}
          />
        </p>
        <div className="loginIn bg-white">
          <img src="/images/logo.png" alt="로고" />
          <h4 className="login_tit">
            우리의 공감소통
            <span className="block">
              미디어 플랫폼 <span className="co_pri">채널W</span>
            </span>
          </h4>
          <p className="s_tit f14_w300 mt-3">
            로그인 후 모든 서비스를 이용할 수 있어요
          </p>
          <div className="input_box flex flex-col">
            <LoginEmailInput
              name="email"
              value={loginState.id}
              onChange={(value) => setLoginState((s) => ({ ...s, id: value }))}
              onKeyDown={onIdKeyDown}
            />
            <div className="mb-4" />
            <OutlineLabelField
              id="login_pw"
              name="password"
              autoComplete="on"
              label="비밀번호"
              // className={"w-full"}
              value={loginState.pw}
              type="password"
              onChange={(value) => setLoginState((s) => ({ ...s, pw: value }))}
              onKeyDown={onPwKeyEnter}
            />
            <div className="mb-4" />
            <button className="pri_btn" type="button" onClick={handleLogin}>
              로그인
            </button>
          </div>
          <div className="info_box flex items-center justify-between">
            <div className="flex all_agree_wrap">
              <input
                id="all_agree"
                className="id_save"
                type="checkbox"
                checked={useSaveId}
                onChange={(e) => setUseSaveId(e.target.checked)}
              />
              <label htmlFor="all_agree" className="aree_text">
                아이디 저장
              </label>
            </div>
            <ul className="flex f14_300 gap-4">
              <li>
                <Link2 to="/join">회원가입</Link2>
              </li>
              <li>
                <a
                  className="cursor-pointer"
                  onClick={() =>
                    onFormChange(`password?email=${loginState.id}`)
                  }
                >
                  비밀번호 찾기
                </a>
              </li>
            </ul>
          </div>
        </div>
        <span className="copyright f12_w300">ⓒ 2023 WOORI BANK WBN</span>
      </div>
      {popup != null && <Popup {...popup} />}
    </>
  );
}
