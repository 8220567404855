import ThumbnailCard from '../../card/ThumbnailCard';
import _ from 'lodash';
import { CircularProgress, Collapse } from '@mui/material';
import usePasswordPopup from '@/components/ucms/common/usePasswordPopup';
import { useMemo } from 'react';

export default function LiveList({ isLoading, list }) {
  const { popup: passwordPopup, onClick } = usePasswordPopup();

  const _list = useMemo(() => {
    return list.map((it) => ({
      ...it,
      onClick: (e) => {
        onClick(e, it.menuId, it.cid, it.link, it.isLocked);
      },
    }));
  }, [list]);

  return (
    <>
      <Collapse in={_list.length > 0}>
        <div className="live_box">
          <div className="inner items-center">
            <h3 className="cont_tit mb-6">생방송</h3>
            {_.chunk(_list, 4).map((row, rowNum) => (
              <div
                key={`thumbnail_card_row_${rowNum}`}
                className="box_wrap grid lg:grid-cols-4 sm:grid-cols-2 gap-y-12 gap-4"
              >
                {row.map((item, colNum) => (
                  <div
                    key={`thumbnail_card_live_${rowNum * 4 + colNum}`}
                    className="col"
                  >
                    <ThumbnailCard option={item} />
                  </div>
                ))}
              </div>
            ))}
            {isLoading && (
              <div className="w-full flex items-center justify-center">
                <CircularProgress className="loading" />
              </div>
            )}
          </div>
        </div>
      </Collapse>
      {passwordPopup}
    </>
  );
}
