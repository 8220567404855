import { useState } from 'react';
import MyPageApi from '@/components/user/my/data/MyPageApi';

export default function useFollower() {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(null);
  const [page, setPage] = useState(1);

  const load = async ({ page = 0, size = 9 } = {}) => {
    setPage(page);
    setIsLoading(true);
    let res = await MyPageApi.followerList({ page, size });
    setList(res.data);
    setTotal(res.totalCount ?? 0);
    setIsLoading(false);
    return res.data;
  };

  return {
    isLoading,
    list,
    total,
    load,
    page,
  };
}
