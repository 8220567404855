import LoginUtil from '@/components/user/login/LoginUtil';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPinCancelCallback,
  setPinRegisterState,
  setPinRequestCanceled,
  setPinSubmitCallback,
} from '@/components/easyLogin/pin/PinReducer';
import MyPageApi from '@/components/user/my/data/MyPageApi';
import useAESCrypto from '@/components/user/useAESCrypto';
import LoginApi from '@/components/user/login/LoginApi';
import { setUserLogin } from '@/components/user/login/LoginReducer';
import useWindowDimensions from '@/lib/useWindowDimensions';
import { useEffect } from 'react';
import { setBioState } from '@/components/easyLogin/bio/BioReducer';

export const pinLength = 4;

export default function usePin() {
  const { isWebView: canRegister } = useWindowDimensions();
  const dispatch = useDispatch();
  const { encrypt } = useAESCrypto();
  const { pinRegistered, pinLoginCanceled } = useSelector(
    (state) => state.pinReducer
  );

  useEffect(() => {
    LoginUtil.getPinKey2().then((pinKey) => {
      if (pinKey != null && pinKey !== '') {
        dispatch(setPinRegisterState(true));
      }
    });
  }, []);

  const removePin = () => {
    dispatch(setPinRegisterState(false));
    LoginUtil.setPinKey(null);
  };
  const registerPin = async ({ pin }) => {
    if (!canRegister) {
      return;
    }
    let { code, ezAuthUUID } = await MyPageApi.getEzPin();
    if (code !== '00') {
      throw Error('등록 오류');
    }
    if (ezAuthUUID == null || ezAuthUUID === '') {
      throw Error('등록 오류');
    }
    const now = new Date();
    const timestamp = Math.floor(now.getTime() / 1000);
    let ezAuthHash = encrypt(`${ezAuthUUID}:${pin}:${timestamp}`);
    let res = await MyPageApi.registerEzPin({
      ezAuthHash,
    });
    if (res.code != '00') {
      throw Error('등록 오류');
    }
    console.log(res);
    LoginUtil.setPinKey(ezAuthUUID);
    dispatch(setPinRegisterState(true));
  };

  const pinLogin = async ({ code, ignoreCancel = false } = {}) => {
    if (!pinRegistered || (pinLoginCanceled && !ignoreCancel) || !canRegister) {
      return false;
    }
    const now = new Date();
    const timestamp = Math.floor(now.getTime() / 1000);
    const id = await LoginUtil.getUserId2();
    const pinKey = await LoginUtil.getPinKey2();
    let ezAuthHash = encrypt(`${pinKey}:${code}:${timestamp}`);
    let res = await LoginApi.ezPinLogin({ id, ezAuthHash, timestamp });
    console.log(pinKey, code, timestamp, ezAuthHash, res);

    if (res.data?.code === '00') {
      dispatch(setUserLogin(res));
    } else {
      throw new Error(
        `간편 로그인 중 오류가 발생하였습니다.\n오류코드 ${res.code}`
      );
    }
    dispatch(setPinSubmitCallback(null));
    return true;
  };

  const requestPinLogin = async ({ ignoreCancel = false } = {}) => {
    if (!pinRegistered || (pinLoginCanceled && !ignoreCancel) || !canRegister) {
      return false;
    }
    return new Promise((res, rej) => {
      dispatch(
        setPinSubmitCallback(async (pin) => {
          let r = await pinLogin({ code: pin, ignoreCancel });
          res(r);
        })
      );
      dispatch(
        setPinCancelCallback(() => {
          res(false);
          dispatch(setPinSubmitCallback(null));
          dispatch(setPinRequestCanceled(true));
        })
      );
    });
  };

  return {
    pinRegistered,
    canRegister,
    removePin,
    registerPin,
    requestPinLogin,
  };
}
