import MenuApi from '@/components/menu/MenuApi';
import {
  createPromiseThunk,
  handleAsyncActions,
  reducerUtils,
} from '@/lib/asyncUtils';
import MyPageApi from '@/components/user/my/data/MyPageApi';

const API_ACTION = {
  GET_MENU_LIST: 'GET_MENU_LIST',
  GET_MENU_LIST_ERROR: 'GET_MENU_LIST_ERROR',
  GET_MENU_LIST_SUCCESS: 'GET_MENU_LIST_SUCCESS',

  GET_EDU_MENU_LIST: 'GET_EDU_MENU_LIST',
  GET_EDU_MENU_LIST_ERROR: 'GET_EDU_MENU_LIST_ERROR',
  GET_EDU_MENU_LIST_SUCCESS: 'GET_EDU_MENU_LIST_SUCCESS',
};
Object.freeze(API_ACTION);

export const getMenuList = createPromiseThunk(
  API_ACTION.GET_MENU_LIST,
  MenuApi.getMenuList
);

export const getEduMenuList = createPromiseThunk(
  API_ACTION.GET_EDU_MENU_LIST,
  MyPageApi.getEducationCategories
);

const initialState = {
  menuList: reducerUtils.initial(),
  eduMenuList: reducerUtils.initial(),
};

export default function menuReducer(state = initialState, action) {
  switch (action.type) {
    case API_ACTION.GET_MENU_LIST:
    case API_ACTION.GET_MENU_LIST_ERROR:
    case API_ACTION.GET_MENU_LIST_SUCCESS:
      return handleAsyncActions(API_ACTION.GET_MENU_LIST, 'menuList')(
        state,
        action
      );

    case API_ACTION.GET_EDU_MENU_LIST:
    case API_ACTION.GET_EDU_MENU_LIST_ERROR:
    case API_ACTION.GET_EDU_MENU_LIST_SUCCESS:
      return handleAsyncActions(API_ACTION.GET_EDU_MENU_LIST, 'eduMenuList')(
        state,
        action
      );

    default:
      return state;
  }
}
