import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import CustomBuildEditor from 'ckeditor5-custom-build/build/ckeditor';
import ContentPasswordField from '@/components/content/editor/ContentPasswordField';
import ContentShowRadio, {
  OpenLevel,
} from '@/components/content/editor/ContentShowRadio';
import ContentShowGroup from '@/components/content/editor/ContentShowField';
import ContentEditorFileField from '@/components/content/editor/ContentEditorFileField';
import ContentEditorThumbnailField from '@/components/content/editor/ContentEditorThumbnailField';
import ContentEditorVideoField from '@/components/content/editor/ContentEditorVideoField';
import ContentEditorImageListField from '@/components/content/editor/ContentEditorImageListField';
import useS3FileUpload, {
  FileUploadState,
  UploadAbortError,
} from '@/components/content/editor/upload/useS3FileUpload';
import UploadPopup from '@/components/popup/UploadPopup';
import Popup from '@/components/popup/Popup';
import ContentApi, {
  ContentDeleteFileData,
  ContentFileJobType,
  ContentFileType,
  ContentJobStatus,
  ContentJobUpdateData,
  ContentJobUpdateFileData,
  ContentRegistData,
  ContentRegistFileData,
} from '@/components/content/ContentApi';
import { getTodayPath } from '@/lib/timeUtil';
import AutoBreadCrumb from '@/components/ucms/common/AutoBreadCrumb';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import { useParams } from 'react-router-dom';
import useMenu from '@/components/menu/useMenu';
import { parseServerHtml } from '@/lib/textUtil';
import useWindowDimensions from '@/lib/useWindowDimensions';
import UcmsMobileHeader from '@/components/ucms/common/UcmsMobileHeader';
import { ContentType } from '@/app/config/ContentType';
import UploadFileDto from '@/components/content/editor/upload/UploadFileDto';
import Config from '@/app/config/config';
import { normalizeNFC } from '@/lib/stringUtil';
import ContentFormUrlField from '@/components/content/editor/ContentFormUrlField';
import ContentEventConfigField from '@/components/content/editor/ContentEventConfigField';
import ContentEditorMenuInput from '@/components/content/editor/ContentEditorMenuInput';
import useDebugWeb from '@/components/debug/useDebugWeb';

export class EditorData {
  /**
   *
   * @param menuId
   * @param cid
   * @param title
   * @param openLvl
   * @param openOpt
   * @param hasPassword
   * @param password
   * @param thumbnail
   * @param video
   * @param images
   * @param body
   * @param contentType
   * @param useReserve
   * @param reserveAt
   * @param videoState
   * @param useThumbnail
   * @param needThumbnail
   * @param thumbnailFile
   * @param isSquareThumbnail
   * @param useVideo
   * @param videoFile
   * @param useImageList
   * @param imageFileList
   * @param useAttachFile
   * @param {[UploadFileDto]} attachFileList
   * @param {[UploadFileDto]} attaches
   * @param files
   * @param useGfForm
   * @param gfUrl
   * @param gfKey
   * @param gfData
   * @param gfBtnText
   * @param useEventConfig
   * @param eventStartDt
   * @param eventEndDt
   * @param eventStatus
   */
  constructor({
    menuId,
    cid,
    title,
    openLvl,
    openOpt,
    hasPassword, // 비밀게시 여부
    password, // 비밀게시 비번
    thumbnail,
    video,
    images,
    body,

    contentType,

    useReserve,
    reserveAt,

    videoState,
    useThumbnail,
    needThumbnail = true,
    thumbnailFile,
    isSquareThumbnail,

    useVideo,
    videoFile,

    useImageList,
    imageFileList,

    useAttachFile,
    attachFileList,
    attaches,

    files,

    useGfForm,
    gfUrl,
    gfKey,
    gfData,
    gfBtnText,

    useEventConfig,
    eventStartDt,
    eventEndDt,
    eventStatus,
  }) {
    this.menuId = menuId;
    this.cid = cid;
    this.title = title;

    this.openLvl = openLvl;
    this.openOpt = openOpt;

    this.hasPassword = hasPassword;
    this.password = password;

    this.video = video; // 신규 파일
    this.thumbnail = thumbnail; // 신규 파일
    this.images = images;
    this.body = body;

    this.useReserve = useReserve;
    this.reserveAt = reserveAt;
    this.videoState = videoState;

    this.needThumbnail = needThumbnail;
    this.useThumbnail = useThumbnail;
    this.thumbnailFile = thumbnailFile;
    this.isSquareThumbnail = isSquareThumbnail;

    this.useVideo = useVideo;
    this.videoFile = videoFile;

    this.useImageList = useImageList;
    this.imageFileList = imageFileList;

    this.useAttachFile = useAttachFile;
    this.attachFileList = attachFileList; // exist file
    this.attaches = attaches; // new file

    this.files = files;

    this.useGfForm = useGfForm;
    this.gfUrl = gfUrl;
    this.gfKey = gfKey;
    this.gfBtnText = gfBtnText;

    this.useEventConfig = useEventConfig;
    this.eventStartDt = eventStartDt;
    this.eventEndDt = eventEndDt;
    this.eventStatus = eventStatus;
  }
}

export const ContentJobType = {
  upload: 'UPLOAD',
  delete: 'DELETE',
};

/**
 *
 * @param {EditorData} data
 * @returns {JSX.Element}
 * @constructor
 */
export default function ContentEditor({ data } = {}) {
  const { findMenu } = useMenu();
  const { menuId } = useParams();
  const navigator = useNavigate2();
  const editorRef = useRef();
  const uploader = useS3FileUpload();
  const [state, _setState] = useState(new EditorData({ ...data }));
  // const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [popup, setPopup] = useState(null);
  const [newCid, setNewCid] = useState(undefined);
  const { isMobile } = useWindowDimensions();

  /**
   * contentType 알 수 없으면 VOD 취급
   * @param menuId
   * @param isReserved
   * @returns {string}
   */
  const getContentType = (menuId, isReserved) => {
    let menu = findMenu(menuId);
    if (menu != null) {
      if (isReserved) {
        // TODO 라디오
        if (menu.mediaYn === 'Y') return ContentType.VL;
        // if(menu.mediaYn === "Y") return ContentType.AL
      } else {
        return menu.contentType;
      }
    }
    return ContentType.BBS;
  };
  const setState = (key, value) => {
    // console.log('setState', key, value);
    _setState((s) => ({
      ...s,
      [key]: value,
    }));
  };
  const _onSubmit = async () => {
    try {
      await regist();
    } catch (e) {
      if (e instanceof UploadAbortError) {
        // 무시, 사용자 취소
      } else {
        console.error(e);
        setPopup({
          title: '저장 오류',
          message:
            '저장 중 오류가 발생했습니다.\n내용을 확인 후 다시 시도해 주세요',
          okCallback: () => {
            setPopup(null);
          },
        });
      }
    }
  };

  const makeFileListForRegister = (path) => {
    let files = [];

    if (state.video != null) {
      files.push(
        new ContentRegistFileData({
          jobGroupType: ContentFileJobType.video,
          jobtype: ContentJobType.upload,
          fileType: ContentFileType.video,
          uploadPath: `${path}/`,
          uploadFile: state.video.name,
        })
      );
    } else if (state.videoFile != null) {
      files.push(
        new ContentRegistFileData({
          ...state.videoFile,
        })
      );
    }

    if (state.thumbnail != null) {
      files.push(
        new ContentRegistFileData({
          jobGroupType: ContentFileJobType.video,
          jobtype: ContentJobType.upload,
          fileType: ContentFileType.userThumbnail,
          uploadPath: `${path}/`,
          uploadFile: state.thumbnail.name,
        })
      );
    }

    if (state.attaches != null && state.attaches.length > 0) {
      // 삭제 안한 신규 파일 업로드 요청
      let list = state.attaches
        .filter((it) => it.deleted !== true)
        .map((f, i) => {
          return new ContentRegistFileData({
            jobGroupType: ContentFileJobType.attach,
            jobtype: ContentJobType.upload,
            fileType: ContentFileType.attach,
            uploadPath: `${path}/`,
            uploadFile: f.name,
          });
        });
      files.push(...list);
    }

    // if(state.imageFileList != null && state.imageFileList.length > 0){
    //     let list = state.imageFileList.map((f, i) => {
    //         return new ContentRegistFileData({
    //             jobGroupType: ContentFileJobType.video,
    //             jobtype: "UPLOAD",
    //             fileType: ContentFileType.userThumbnail,
    //             uploadPath: `${path}/`,
    //             uploadFile: f.name,
    //         })
    //     })
    //     files.push(...list)
    // }

    return files;
  };
  const makeFileListForModify = (path) => {
    let files = [];

    // 영상 첨부
    if (state.video != null) {
      files.push(
        new ContentRegistFileData({
          jobGroupType: ContentFileJobType.video,
          jobtype: ContentJobType.upload,
          fileType: ContentFileType.video,
          uploadPath: `${path}/`,
          uploadFile: state.video.name,
        })
      );
      if (state.files != null && files.length > 0) {
        // 새 영상 첨부시, 이전 모든 영상, 자동생성 썸네일 삭제
        let thumbnails = state.files
          .filter(
            (it) =>
              it.fileType == ContentFileType.thumbnail ||
              it.fileType == ContentFileType.video
          )
          .map(
            (it) =>
              new ContentDeleteFileData({
                jobGroupType: ContentFileJobType.video,
                jobtype: ContentJobType.delete,
                fileId: it.fileId,
                fileType: it.fileType,
                uploadPath: it.cdnPath,
                uploadFile: it.cdnFile1,
              })
          );
        if (thumbnails.length > 0) {
          files.push(...thumbnails);
        }
      }
    }

    // 사용자 썸네일 첨부
    if (state.thumbnail != null) {
      files.push(
        new ContentRegistFileData({
          jobGroupType: ContentFileJobType.video,
          jobtype: ContentJobType.upload,
          fileType: ContentFileType.userThumbnail,
          uploadPath: `${path}/`,
          uploadFile: state.thumbnail.name,
        })
      );

      if (state.files != null && files.length > 0) {
        let thumbnails = state.files
          .filter((it) => it.fileType == ContentFileType.userThumbnail)
          .map(
            (it) =>
              new ContentDeleteFileData({
                jobGroupType: ContentFileJobType.video,
                jobtype: ContentJobType.delete,
                fileId: it.fileId,
                fileType: it.fileType,
                uploadPath: it.cdnPath,
                uploadFile: it.cdnFile1,
              })
          );
        if (thumbnails.length > 0) {
          files.push(...thumbnails);
        }
      }
    }

    if (state.attaches != null && state.attaches.length > 0) {
      // 신규 파일 업로드 요청
      let list = state.attaches
        .filter((it) => it.deleted !== true)
        .map((f, i) => {
          return new ContentRegistFileData({
            jobGroupType: ContentFileJobType.attach,
            jobtype: ContentJobType.upload,
            fileType: ContentFileType.attach,
            uploadPath: `${path}/`,
            uploadFile: f.name,
          });
        });
      files.push(...list);
    }

    if (state.attachFileList != null && state.attachFileList.length > 0) {
      // 첨부파일 삭제 요청
      let deleteFiles = state.attachFileList
        .filter((it) => it.deleted === true)
        .map(
          (it) =>
            new ContentDeleteFileData({
              jobGroupType: ContentFileJobType.attach,
              jobtype: ContentJobType.delete,
              fileId: it.id,
              fileType: ContentFileType.attach,
              uploadPath: it.cdnPath,
              uploadFile: it.cdnFile1,
            })
        );
      if (deleteFiles.length > 0) {
        files.push(...deleteFiles);
      }
    }

    return files;
  };

  /**
   *
   * @param {ContentRegistData} data
   * @returns {boolean}
   */
  const validate = (data) => {
    if (data.title == null || data.title === '') {
      setPopup({
        title: '제목을 입력해 주세요',
        message: '',
        okCallback: () => {
          setPopup(null);
        },
      });
      return false;
    } else if (
      data.contentType === ContentType.VOD ||
      data.contentType === ContentType.VOD_USER ||
      data.contentType === ContentType.VL ||
      data.contentType === ContentType.AL
    ) {
      let hasExistVideo =
        state.videoFile != null &&
        data.files.find(
          (it) =>
            it.fileId === state.videoFile.fileId &&
            it.jobtype === ContentJobType.delete
        ) == null;
      let hasNewVideo =
        data.files.find(
          (it) =>
            it.fileType === ContentFileType.video &&
            it.jobtype === ContentJobType.upload
        ) != null;
      if (!hasExistVideo && !hasNewVideo) {
        setPopup({
          title: '동영상을 선택해 주세요',
          message: '',
          okCallback: () => {
            setPopup(null);
          },
        });
        return false;
      }
    }
    if (state.needThumbnail) {
      let hasExistThumbnail =
        state.thumbnailFile != null &&
        data.files.find(
          (it) =>
            it.fileId === state.thumbnailFile.fileId &&
            it.jobtype === ContentJobType.delete
        ) == null;
      let hasNewThumbnail =
        data.files.find(
          (it) =>
            it.fileType === ContentFileType.userThumbnail &&
            it.jobtype === ContentJobType.upload
        ) != null;
      if (!hasExistThumbnail && !hasNewThumbnail) {
        setPopup({
          title: '썸네일을 선택해 주세요',
          message: '',
          okCallback: () => {
            setPopup(null);
          },
        });
        return false;
      }
    } else {
      if (data.cData == null || data.cData === '') {
        setPopup({
          title: '내용을 입력해 주세요',
          message: '',
          okCallback: () => {
            setPopup(null);
          },
        });
        return false;
      }
    }

    if (data.openLvl === OpenLevel.target) {
      if (data.openOpt?.trim() === '') {
        setPopup({
          title: '부분 공개 대상을 선택해 주세요',
          message: '',
          okCallback: () => {
            setPopup(null);
          },
        });
        return false;
      }
    }

    if (data.menuId === 2) {
      if (subMenuId == null || subMenuId === '') {
        setPopup({
          title: '카테고리를 선택해 주세요',
          message: '',
          okCallback: () => {
            setPopup(null);
          },
        });
        return false;
      }
    }

    return true;
  };

  const regist = async () => {
    const isRegister = (data.cid ?? newCid) == null;
    let body = editorRef.current?.editor?.getData();

    let path = getTodayPath();
    let files;
    if (isRegister) {
      files = makeFileListForRegister(path);
    } else {
      files = makeFileListForModify(path);
    }

    let d = new ContentRegistData({
      cid: data.cid ?? newCid,
      menuId: subMenuId ? subMenuId : data.menuId,
      title: normalizeNFC(
        menuInputValue ? `[${menuInputValue}] ${state.title}` : state.title
      ),
      cData: body,
      contentType: getContentType(
        data.menuId,
        state.useReserve && state?.reserveYn === 'Y'
      ),
      contentPwdYn: state.hasPassword ? 'Y' : 'N',
      contentPwd: state.password,
      topFixedYn: 'N',
      exposureYn: 'Y',
      reserveYn: 'N',
      reserveDt: '',
      openLvl: state.openLvl,
      openOpt: state.openOpt,
      files: files,
      gfUrl: state.gfUrl,
      gfKey: state.gfKey,
      gfBtnText: state.gfBtnText,
      eventStatus: state.eventStatus,
      eventStartDt: state.eventStartDt?.format('YYYY-MM-DD'),
      eventEndDt: state.eventEndDt?.format('YYYY-MM-DD'),
    });

    if (!validate(d)) {
      return;
    }

    let resData;
    if (isRegister) {
      resData = await ContentApi.contentRegist(d);
    } else {
      resData = await ContentApi.contentModify(d);
    }
    const cid = resData?.cid;

    if (cid == null) {
      throw new Error('컨텐츠 등록 실패');
    }

    setNewCid(cid);

    await uploadFiles(resData, path, cid);
  };

  const { sendLog } = useDebugWeb();

  const uploadFiles = async (resData, path, cid) => {
    try {
      if (state.thumbnail != null) {
        let fileInfo = findFileRes(resData, state.thumbnail);
        await uploader.upload(fileInfo, path, state.thumbnail);
      }

      if (state.video != null) {
        let fileInfo = findFileRes(resData, state.video);
        await uploader.upload(fileInfo, path, state.video);
      }

      if (state.imageFileList != null) {
        let imageFiles = state.imageFileList;
        for (let index in imageFiles) {
          let fileInfo = findFileRes(resData, imageFiles[index]);
          await uploader.upload(fileInfo, path, imageFiles[index]);
        }
      }

      for (let index in state.attaches) {
        const file = state.attaches[index];
        if (file.deleted === true) {
          continue;
        }
        let fileInfo = findFileRes(resData, file);
        await uploader.upload(fileInfo, path, file, 'attach');
      }

      setPopup({
        title: '컨텐츠가 등록되었습니다.',
        message: '',
        okMessage: '확인',
        okCallback: () => {
          setPopup(null);
          navigator(
            `/ucms/view/${subMenuId ? subMenuId : data.menuId}/${cid}`,
            { replace: true }
          );
        },
      });
    } catch (e) {
      if (e instanceof UploadAbortError) {
        // 무시, 사용자 취소
      } else {
        console.error(e);
        // sendLog({
        //   log: '로그 테스트',
        //   message: e.message,
        // });
        setPopup({
          title: '업로드 오류',
          message:
            '업로드 중 오류가 발생했습니다.\n내용을 확인 후 다시 시도해 주세요',
          okCallback: () => {
            setPopup(null);
          },
        });
      }
    }
  };

  const findFileRes = (resData, file) => {
    // 파일명으로 매칭
    for (let index in resData.data) {
      let resFile = resData.data[index];
      if (resFile.uploadFile === file.name) {
        return resFile;
      }
    }
    // timestamp로 매팅
    for (let index in resData.data) {
      let resFile = resData.data[index];
      let resTimestamp = /(\d{13})\./.exec(resFile.uploadFile)?.[1];
      let fileTimestamp = /(\d{13})\./.exec(file.name)?.[1];
      if (
        resTimestamp != null &&
        fileTimestamp != null &&
        resTimestamp === fileTimestamp
      ) {
        return resFile;
      }
    }
    return null;
  };

  useEffect(() => {
    if (uploader.state === FileUploadState.uploaded) {
      let fileInfo = uploader.data.fileInfo;
      ContentApi.contentJobUpdate(
        new ContentJobUpdateData({
          cid: fileInfo.cid,
          files: [
            new ContentJobUpdateFileData({
              ...fileInfo,
              jobGroupSt: ContentJobStatus.working,
              jobGroupPrg: 20,
              jobSt: ContentJobStatus.success,
              jobPrg: 100,
            }),
          ],
        })
      );
    }
  }, [uploader.state]);

  const uploadPopupOption = useMemo(() => {
    if (uploader.state === FileUploadState.uploading) {
      // console.log('uploadPopupOption', uploader);
      return {
        fileName: uploader.data.file.name,
        progress: Math.floor(
          (uploader.uploaded / uploader.data.file.size) * 100
        ),
        cancelCallback: uploader.cancel,
      };
    } else {
      return null;
    }
  }, [uploader]);

  const menuName = useMemo(() => {
    return findMenu(data?.menuId);
  }, [data]);

  const [menuInputValue, setMenuInput] = useState('');
  const [subMenuId, setSubMenuId] = useState('');
  const onChange = (menuId, menuNm) => {
    setMenuInput(menuNm);
    setSubMenuId(menuId);
  };

  return (
    <div className="read_inner">
      <div className="mt-8" />
      {isMobile && (
        <UcmsMobileHeader
          title={data.menuId === 2 ? '우리튜브' : menuName?.menuNm}
        />
      )}
      <AutoBreadCrumb menuId={data.menuId} showOnMobile={false} />
      <div className="mb-6" />
      {data.menuId === 2 && (
        <ContentEditorMenuInput
          onChange={onChange}
          value={menuInputValue}
          className="editor_menu_input"
        />
      )}
      <input
        className="wetube_read_tit md:pb-8 pb-4 border-b mb-8 w-full"
        placeholder="제목을 입력해 주세요"
        value={state.title}
        onChange={(e) => setState('title', e.target.value)}
      />

      <form>
        <div className="mb-2 flex items-center justify-between flex-wrap gap-5">
          <ContentShowRadio
            value={state.openLvl}
            onChange={(value) => setState('openLvl', value)}
          />
          <div className="flex all_agree_wrap items-center flex-wrap h-12">
            <div className="flex items-center sm:mb-0 mb-2">
              <ContentPasswordField
                hasPassword={state.hasPassword}
                password={state.password}
                onPasswordChange={(v) => setState('password', v)}
                onHasPasswordChange={(v) => setState('hasPassword', v)}
              />
            </div>
          </div>
        </div>
        <ContentShowGroup
          openLvl={state.openLvl}
          openOpt={state.openOpt}
          onChange={(value) => setState('openOpt', value)}
        />
        <div className="mt-4 file_row_wrap grid md:grid-cols-3 gap-2 flex-nowrap">
          {state.useThumbnail && (
            <ContentEditorThumbnailField
              option={{
                needThumbnail: state.needThumbnail,
                thumbnailFile: state.thumbnailFile,
                thumbnail: state.thumbnail,
                isSquareThumbnail: state.isSquareThumbnail,
                onFile: (file) =>
                  setState('thumbnail', UploadFileDto.fromFile(file)),
              }}
            />
          )}
          {state.useVideo && (
            <ContentEditorVideoField
              option={{
                videoFile: state.videoFile,
                video: state.video,
                onFile: (file) =>
                  setState('video', UploadFileDto.fromFile(file)),
              }}
            />
          )}
        </div>
        {state.useImageList && (
          <ContentEditorImageListField
            option={{
              imageUrlList: state.images,
              onFiles: (files) =>
                setState(
                  'imageFileList',
                  files.map((file) => UploadFileDto.fromFile(file))
                ),
            }}
          />
        )}

        {state.useGfForm && (
          <div>
            <ContentFormUrlField
              value={state.gfUrl}
              json={state.gfData}
              buttonText={state.gfBtnText}
              onButtonTextChange={(value) => setState('gfBtnText', value)}
              onChange={(value) => setState('gfUrl', value)}
            />
          </div>
        )}

        {state.useEventConfig && (
          <div>
            <ContentEventConfigField
              eventStatus={state.eventStatus}
              eventStartDt={state.eventStartDt}
              eventEndDt={state.eventEndDt}
              onStatusChange={(v) => setState('eventStatus', v)}
              onStartChange={(v) => setState('eventStartDt', v)}
              onEndChange={(v) => setState('eventEndDt', v)}
            />
          </div>
        )}

        <div className="mt-5 mb-10 rounded-lg">
          <CKEditor
            ref={editorRef}
            className="mt-5 mb-10 rounded-lg"
            editor={CustomBuildEditor}
            config={{
              uploadConfig: {
                accessKey: 'r8aylLOvCr21ExIk',
                secretKey: 'SuY8mpjiuITjhkungrwUdUjXhDQqZ6E2',
                region: 'kr-standard',
                endPoint: Config.s3Url,
              },
            }}
            data={parseServerHtml(state.body)}
          />
        </div>

        {state.useAttachFile && (
          <ContentEditorFileField
            option={{
              newList: state.attaches,
              existList: state.attachFileList,
              onNewList: (files) => setState('attaches', files),
              onExistList: (files) => setState('attachFileList', files),
            }}
          />
        )}

        <div className="flex gap-4">
          <button
            type="button"
            className="pri_btn_stroke"
            onClick={(e) => navigator(-1)}
          >
            취소
          </button>
          {state.useReserve && (
            <button type="button" className="pri_btn_stroke_blue">
              예약
            </button>
          )}
          <button type="button" className="pri_btn" onClick={() => _onSubmit()}>
            등록
          </button>
        </div>
      </form>

      <div className="mb-32" />

      <div id="upload_popup" />

      {/*{*/}
      {/*    uploader.state === FileUploadState.uploading &&*/}
      {/*    <span>업로드 중 {Math.floor(uploader.uploaded / uploader.data.file.size * 100)}% <button onClick={uploader.cancel}>취소</button></span>*/}
      {/*}*/}

      {uploadPopupOption != null && <UploadPopup {...uploadPopupOption} />}

      {popup != null && <Popup {...popup} />}
    </div>
  );
}
