import React, { useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import useWindowDimensions from '@/lib/useWindowDimensions';
import PrivacyInnerPopup from './PrivacyInnerPopup';
import MyPageApi from '../user/my/data/MyPageApi';

export default function PrivacyPopup({ children, setFirstLogin, userNo }) {
  const { isMobile } = useWindowDimensions();
  const [show, setShow] = useState(true);
  const [showPrivacyInnerPopup, setShowPrivacyInnerPopup] = useState(false);
  const [privacyInnerPopupType, setPrivacyInnerPopupType] = useState('');

  const setPrivacyPopupValue = async () => {
    const _popup = await MyPageApi.setPrivacyPopup(userNo);
    if (_popup?.code === '00') console.log('팝업 해제');
  };

  const onClose = () => {
    setFirstLogin();
    setShow(false);
    setPrivacyPopupValue();
  };

  const onCloseInner = () => {
    setShowPrivacyInnerPopup(false);
  };

  const onShowPrivacy = (innerHtmType) => {
    setPrivacyInnerPopupType(innerHtmType);
    setShowPrivacyInnerPopup(true);
  };

  const positionClass = useMemo(() => {
    if (isMobile) {
      return 'top-[50%] left-[50%] transform translate-x-[-50%] privacyPopup mobile';
    } else {
      return 'top-[120px] left-[100px] privacyPopup';
    }
  }, [isMobile]);

  return (
    <>
      {children}
      {show && (
        <div
          className={`default-unwrap bg-white ] z-[100] fixed transition-all ${positionClass}`}
        >
          {/*보더 영역*/}
          <div className="p-[2px] bg-[#fff]">
            {/*스크롤 영역*/}
            <div
              className="bg-white w-[500px] max-h-[90vh] max-w-[90vw]"
              style={{
                height: '100%',
              }}
            >
              <div
                className="overflow-auto w-[500px] max-h-[90vh]"
                style={{
                  height: '100%',
                  maxWidth: '97vw',
                }}
              >
                <div className="w-full font-extrabold p-2 pt-6 bg-white leading-none">
                  [우리은행] <br /> <br /> 개인정보보호법 제20조의2(개인정보
                  이용·제공 내역의 통지)
                </div>
                <div
                  className="p-2 bg-white"
                  style={{
                    height: '100%',
                  }}
                >
                  안녕하세요. 고객님 채널W 서비스를 이용해주셔서 감사합니다.{' '}
                  <br /> <br />
                  개인정보보호법 제20조의2(개인정보 이용·제공 내역의 통지) 및
                  동법 시행령 제15조의3에 따라 채널W 서비스를 이용하시는
                  고객님께 연 1회 개인정보 이용·제공 내역을 안내 드립니다.
                  <br /> <br />본 메시지는 마케팅 수신동의여부와 상관없이
                  관련법령에 따라 채널W 서비스 전 고객에게 발송됩니다.
                  <br /> <br /> 고객님의 소중한 개인정보는 관련 법령에 따라
                  안전하게 보호되며, 자세한 내용은 개인정보 이용·제공 내역 확인
                  및 개인정보 처리방침에서 언제든지 언제든지 확인하실 수
                  있습니다.
                  <br /> <br />☞{' '}
                  <a onClick={() => onShowPrivacy('PrivacyTerm')}>
                    개인정보 이용·제공내역 확인
                  </a>
                  <br />☞{' '}
                  <a onClick={() => onShowPrivacy('PrivacyTerm2')}>
                    개인정보 처리방침
                  </a>
                  <br /> <br />
                  ※기타 궁금하신 사항은 우리은행 고객센터(1588-5000)로
                  문의하시기 바랍니다.
                </div>
              </div>
            </div>
            <div
              className="absolute top-[2px] right-[2px] w-[26px] h-[27px] cursor-pointer"
              style={{
                border: '1px solid',
              }}
              onClick={onClose}
            >
              <CloseIcon className="!w-full !h-full text-white" />
            </div>
          </div>
        </div>
      )}
      {showPrivacyInnerPopup && (
        <div
          className={`default-unwrap bg-white ] z-[100] fixed transition-all innerPopup ${positionClass}`}
        >
          <PrivacyInnerPopup
            type={`${privacyInnerPopupType}`}
            isMobile={isMobile}
          />
          <div
            className="absolute top-[2px] right-[2px] w-[26px] h-[27px] cursor-pointer bg-white"
            style={{
              border: '1px solid',
            }}
            onClick={onCloseInner}
          >
            <CloseIcon className="!w-full !h-full text-white" />
          </div>
        </div>
      )}
    </>
  );
}
