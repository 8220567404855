import JoinPrivacyMustTermPopupForNormal from '@/page/join/JoinPrivacyMustTermPopupForNormal';
import JoinPrivacyOptionalTermPopup from '@/page/join/JoinPrivacyOptionalTermPopup';
import JoinPrivacyThirdPartyTermPopup from '@/page/join/JoinPrivacyThirdPartyTermPopup';
import { useEffect, useMemo, useState } from 'react';
import TermCheck from '@/components/form/TermCheck';
import classNames from 'classnames';
import useLogin from '@/components/user/login/useLogin';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import { Collapse } from '@mui/material';
import MyPageApi from '@/components/user/my/data/MyPageApi';
import JoinPrivacyMustTermPopupForEmployee from '@/page/join/JoinPrivacyMustTermPopupForEmployee';

export const RequiredFields = ['agree1Yn'];
export const TermsFields = ['agree1Yn', 'agree2Yn', 'agree3Yn'];

export default function MyTermUpdatePage() {
  const { isLoggedIn, data, sessionLogin } = useLogin();
  const navigate = useNavigate2();

  const [showPrivacyMustPopup, setShowPrivacyMustPopup] = useState(false);
  const [showPrivacyOptionalPopup, setShowPrivacyOptionalPopup] =
    useState(false);
  const [showThirdPrivacyPopup, setShowThirdPrivacyPopup] = useState(false);

  const isPopupShow = useMemo(() => {
    return (
      showPrivacyOptionalPopup || showPrivacyMustPopup || showThirdPrivacyPopup
    );
  }, [showPrivacyOptionalPopup, showPrivacyMustPopup, showThirdPrivacyPopup]);

  const [formData, setFormData] = useState({});

  const isCo = useMemo(() => {
    return data?.compCd !== 0;
  }, [data]);

  useEffect(() => {
    let fields = {};
    for (let i in TermsFields) {
      const field = TermsFields[i];
      fields[field] = data[field] === 'Y';
    }
    console.log(fields);
    setFormData((p) => ({
      ...p,
      ...fields,
    }));
  }, []);

  const updateData = (key, value) => {
    setFormData((s) => ({
      ...s,
      [key]: value,
    }));
  };

  const handleSubmit = () => {
    for (let i in RequiredFields) {
      const field = RequiredFields[i];
      if (formData[field] !== true) {
        setFormData((p) => ({
          ...p,
          termMessage: '필수 약관 동의가 필요합니다.',
        }));
        return;
      }
    }
    setFormData((p) => ({
      ...p,
      termMessage: null,
    }));
    submit();
  };

  const submit = async () => {
    let param = {};

    for (let i in TermsFields) {
      const field = TermsFields[i];
      param[field] = formData[field] === true ? 'Y' : 'N';
    }

    try {
      let res = await MyPageApi.changeUserAgree(param);
      if (res.code === '00') {
        // TODO 세션 업데이트로 바뀌는지 확인해야함.
        await sessionLogin();
        navigate('/'); // 세션 고침 문제 어떻게하지?
      } else {
        setFormData((p) => ({
          ...p,
          termMessage: '오류가 발생하였습니다. 잠시 후 시도해 주세요',
        }));
      }
    } catch (e) {
      console.error(e);
      setFormData((p) => ({
        ...p,
        termMessage: '오류가 발생하였습니다. 잠시 후 시도해 주세요',
      }));
    }
  };

  if (!isLoggedIn) {
    navigate('/');
  }

  return (
    <div id="contents">
      <div className="join_cont flex justify-center items-center">
        <div className="inner_box">
          <div
            className={classNames({
              join_box: true,
              'min-h-[600px]': isPopupShow,
            })}
          >
            <div className="mb-10" />
            <h4 className="join_tit f28_w700 mb-8"> 개인정보 동의</h4>
            <div className="mb-10" />
            <div className="outline_field error">
              <Collapse in={formData.termMessage != null}>
                <div
                  className={'outline_field_message error'}
                  style={{ height: '100%', padding: '10px 16px' }}
                >
                  {formData.termMessage}
                </div>
              </Collapse>
            </div>
            <TermCheck
              id={'agree1Yn'}
              text="개인정보 수집·이용 동의(필수)"
              checked={formData.agree1Yn}
              onChange={(value) => updateData('agree1Yn', value)}
              onDetail={() => setShowPrivacyMustPopup(true)}
            />
            <TermCheck
              id={'agree2Yn'}
              text="개인정보 수집·이용 동의(선택)"
              checked={formData.agree2Yn}
              onChange={(value) => {
                updateData('agree2Yn', value);
              }}
              onDetail={() => setShowPrivacyOptionalPopup(true)}
            />
            <TermCheck
              id={'agree3Yn'}
              text="개인정보 제3자 제공 동의(선택)"
              checked={formData.agree3Yn}
              onChange={(value) => {
                updateData('agree3Yn', value);
              }}
              onDetail={() => setShowThirdPrivacyPopup(true)}
            />
            <div className="mb-10" />
            <button
              type="submit"
              className="pri_btn mb-4"
              onClick={handleSubmit}
            >
              저장하기
            </button>
          </div>
          <div
            id="term_detail_portal"
            className={classNames({
              absolute: true,
              'top-0 left-0 right-0 bottom-0': isPopupShow,
            })}
          />
        </div>
      </div>

      {showPrivacyMustPopup && (
        <>
          {isCo && (
            <JoinPrivacyMustTermPopupForEmployee
              onAgree={(agree) => updateData('agree1Yn', agree)}
              onClose={() => setShowPrivacyMustPopup(false)}
            />
          )}
          {!isCo && (
            <JoinPrivacyMustTermPopupForNormal
              onAgree={(agree) => updateData('agree1Yn', agree)}
              onClose={() => setShowPrivacyMustPopup(false)}
            />
          )}
        </>
      )}
      {showPrivacyOptionalPopup && (
        <JoinPrivacyOptionalTermPopup
          onAgree={(agree) => updateData('agree2Yn', agree)}
          onClose={() => setShowPrivacyOptionalPopup(false)}
        />
      )}
      {showThirdPrivacyPopup && (
        <JoinPrivacyThirdPartyTermPopup
          onAgree={(agree) => updateData('agree3Yn', agree)}
          onClose={() => setShowThirdPrivacyPopup(false)}
        />
      )}
    </div>
  );
}
