import { useEffect, useMemo, useState } from 'react';
import useCountDown from './useCountDown';
import classNames from 'classnames';
import {
  dateFormat,
  mmssToSec,
  newDate,
  secToMmss,
} from '../../../lib/timeUtil';
import Config from '@/app/config/config';

export const LiveState = {
  none: 'none',
  live: 'live',
  end: 'end',
  reserved: 'reserved',
  countdown: 'countdown',
  waitOpen: 'waitOpen',
};

/**
 *
 * @param {ThumbnailOption} option
 * @param {string} imgClassName
 * @param {string} className
 * @constructor
 */
export default function Thumbnail({ option, className, imgClassName }) {
  const { remainTime } = useCountDown({
    autoStart: true,
    targetTime: option?.liveStartTime,
  });

  const [hover, setHover] = useState(false);
  const isEventEnded = useMemo(() => {
    const eventStatus = option.eventStatus;
    let eventEndDt = null;
    if (option.eventEndDt != null && option.eventEndDt !== '') {
      eventEndDt = newDate(option.eventEndDt);
    }

    // Ready, Ing, End
    // 이벤트 명시적 종료 상태
    if (eventStatus === 'E') {
      return true;
    }

    // 이벤트 종료일 없음
    if (eventEndDt == null) {
      return false;
    }

    const now = new Date();

    // 이벤트 종료일 전
    if (eventEndDt - now > 0) {
      return false;
    }

    // 이벤트 종료일 이후
    return true;
  }, [option]);

  const categoryLabel = useMemo(() => {
    if (option.isLms) {
      if (option.isSmall) {
        return null;
      } else {
        return option?.category;
      }
    } else if (isEventEnded && option?.category === '이벤트') {
      return '종료된 이벤트';
    } else {
      return option?.category;
    }
  }, [option, isEventEnded]);

  const showTopRightLabel = useMemo(() => {
    return option.isSmall !== true && option.isLms;
  }, [option]);

  const topRightLabel = useMemo(() => {
    if (option.eduEndDt == null || option.eduEndDt === '') {
      return null;
    }
    let target = newDate(option.eduEndDt);
    let now = new Date();
    let remainMillis = target.getTime() - now.getTime();
    if (remainMillis < 0) {
      return null;
    } else {
      let inDay = Math.floor(remainMillis / (1000 * 60 * 60 * 24));
      return `D-${inDay.toString().padStart(2, '0')}`;
    }
  }, [option]);

  const showBottomLeftLabel = useMemo(() => {
    return !option.isSmall && option.isLms && option.duration != null;
  }, [option]);

  const durationSec = useMemo(() => {
    return option?.duration == null ? null : mmssToSec(option.duration);
  }, [option]);

  const bottomLeftLabelText = useMemo(() => {
    if (option.isLms) {
      if (option.eduPrg == null) return null;

      if (option.completionYn === 'Y') return '완료';
      else {
        const progress = option.eduPrg < 100 ? option.eduPrg : 100;
        return `${progress}%`;
      }
    }
    return showBottomLeftLabel && durationSec != null && durationSec > 0
      ? `${Math.floor(((option?.viewSec ?? 0) / durationSec) * 100)}%`
      : null;
  }, [showBottomLeftLabel, option]);

  const liveState = useMemo(() => {
    if (option.isNowLive === true) {
      if (option.liveSt === 'B') {
        return LiveState.live;
      }
      if (remainTime < 1000 * 60 * 60) {
        if (remainTime > 0) {
          return LiveState.countdown;
        } else {
          return LiveState.waitOpen;
        }
      } else {
        return LiveState.reserved;
      }
    } else {
      return LiveState.none;
    }
  }, [option, remainTime]);
  const showBlur = useMemo(() => {
    if (
      liveState === LiveState.reserved ||
      liveState === LiveState.waitOpen ||
      liveState === LiveState.countdown
    ) {
      return true;
    }
    return false;
  }, [liveState]);

  const userSeenPos = useMemo(() => {
    let lastSec = option?.lastSec;
    if (lastSec != null && lastSec > 0) {
      if (durationSec != null) {
        return Math.min(Math.ceil((lastSec / durationSec) * 100), 100);
      }
    }
    return null;
  }, [option]);

  useEffect(() => {
    const callback = (e) => {
      // console.log('thumbnailPlay', e.detail.id, option?.id);
      if (e.detail.id !== option?.id) {
        setHover(false);
      }
    };
    window.addEventListener('thumbnailPlay', callback);
    return () => {
      window.removeEventListener('thumbnailPlay', callback);
    };
  }, []);

  const canThumbnailVideoPlay = useMemo(() => {
    return (
      hover &&
      option?.thumbnailVideoUrl != null &&
      option?.isLocked === false &&
      option?.isTranscoding !== true &&
      option?.isSelectMode !== true &&
      option?.isNowLive !== true
    );
  }, [hover, option]);

  useEffect(() => {
    if (hover === true && canThumbnailVideoPlay === true) {
      window.dispatchEvent(
        new CustomEvent('thumbnailPlay', { detail: option })
      );
    }
  }, [hover]);

  return (
    <div
      className={classNames({
        board_wrap: option?.type === 'image',
        [className ?? '']: true,
      })}
      onTouchStart={() => setHover(true)}
      onTouchMove={() => setHover(true)}
      onTouchEnd={() => setHover(false)}
      onTouchCancel={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
      onMouseUp={() => setHover(false)}
      onMouseOut={() => setHover(false)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={classNames({
          thumbnail: true,
        })}
      >
        {canThumbnailVideoPlay && (
          <div className="thumbnail thumbnail_img overflow-clip pointer-events-none">
            <video
              className={`absolute thumbnail_img ${
                imgClassName ?? ''
              } !object-contain bg-black`}
              src={option?.thumbnailVideoUrl}
              autoPlay={true}
              muted={true}
              playsInline={true}
            />
          </div>
        )}

        {!canThumbnailVideoPlay && (
          <div className="thumbnail thumbnail_img overflow-clip">
            <img
              className={`thumbnail_img ${imgClassName ?? ''} ${
                option?.isPortrait === true ? '!object-contain bg-black' : ''
              }`}
              src={option?.thumbnailUrl}
              style={{
                filter: `${showBlur ? 'blur(2px)' : ''} 
                ${isEventEnded ? 'brightness(0.4)' : ''}
                `,
              }}
              onError={(e) => {
                e.target.src = Config.noThumbnail;
                e.target.className = `${e.target.className} border`;
              }}
            />
          </div>
        )}

        {categoryLabel != null && (
          <div className="top_label">
            <span className="label">{categoryLabel}</span>
            {/*카테고리 옆 상단 문구*/}
            {liveState === LiveState.countdown && (
              <span className="text top live_text_border">
                곧 방송이 시작됩니다.
              </span>
            )}
            {liveState === LiveState.reserved && (
              <span className="text top live_text_border">
                {dateFormat({
                  value: option?.liveStartTime,
                  format: 'a/p HH:mm',
                  apText: ['AM', 'PM'],
                })}
              </span>
            )}
          </div>
        )}

        {option?.isLocked === true && (
          <img
            className="lock shadow"
            src="/images/ico_lock_white.svg"
            alt="잠금"
          />
        )}

        {showTopRightLabel === true && topRightLabel != null && (
          <div className="top_right_label">
            <span
              className="label"
              style={{
                backgroundColor: option?.isLms === true ? '#00a2ff' : 'white',
                color: option?.isLms === true ? 'white' : 'black',
              }}
            >
              {topRightLabel}
            </span>
          </div>
        )}

        {showBottomLeftLabel === true && bottomLeftLabelText != null && (
          <span className="bottom_left_label">{bottomLeftLabelText}</span>
        )}

        {option?.isTranscoding === true && (
          <div className="bottom_right_label">
            <span className="label">변환중</span>
          </div>
        )}

        {option?.isSelectMode === true && (
          <div className="flex thumb_check lock">
            <input
              id={`${option?.id}_check`}
              className="id_save"
              type="checkbox"
              checked={option?.isSelected === true}
              onChange={(e) => {
                // e.preventDefault();
                e.stopPropagation();
                option?.onSelectChange(e.target.checked);
              }}
            />
            <label
              htmlFor={`${option?.id}_check`}
              className="check_text"
            ></label>
          </div>
        )}
        {option?.type !== 'image' && (
          <>
            {/*날짜 혹은 시간 카운트다운*/}
            {liveState === LiveState.countdown && (
              <div className="timer countdown live_text_border">
                {secToMmss(remainTime / 1000)}
              </div>
            )}
            {liveState === LiveState.reserved && (
              <div className="timer schedule live_text_border">
                {dateFormat({
                  value: option?.liveStartTime,
                  format: 'M월 dd일',
                })}
              </div>
            )}

            {liveState === LiveState.waitOpen && (
              <div className="timer waitOpen live_text_border text-center">
                곧 방송이
                <br />
                시작됩니다.
              </div>
            )}

            {liveState === LiveState.live && (
              <span className="state">
                <img src="/images/ico_wifi.png" alt="라이브 아이콘" />
                LIVE
              </span>
            )}
            {liveState !== LiveState.live && (
              <>
                {option?.isTranscoding !== true &&
                  option?.duration != null &&
                  option?.duration !== '' && (
                    <span className="play_time">{option.duration}</span>
                  )}
                {userSeenPos != null && (
                  <div className="pl-3 pr-3">
                    <div className="streaming">
                      <span
                        style={{
                          width: `${userSeenPos}%`,
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

/**
 * ThumbnailCardOption 의 서브셋
 * @typedef {Object} ThumbnailOption
 * @property {string} thumbnailUrl
 * @property {string} category
 * @property {boolean} isNowLive
 * @property {boolean} isLocked
 * @property {number} userSeenPosition
 * @property {number} duration
 * @property {string} liveStartTime
 * @property {string} type
 * @property {string} thumbnailVideoUrl
 */
export class ThumbnailOption {
  constructor({
    id,
    isSelectMode = false,
    isSelected = false,
    onSelectChange,
    isPortrait = false,
    thumbnailUrl,
    category,
    isNowLive,
    isLocked,
    duration,
    audioDuration,
    liveStartTime,
    type,
    liveSt,
    isTranscoding,
    thumbnailVideoUrl,
    lastSec,
    viewSec,
    totalSec,
    eventStartDt,
    eventEndDt,
    eventStatus,
    isLms,
    eduGrpNm,
    completionYn,
    eduEndDt,
    isSmall,
    eduPrg,
  }) {
    this.id = id;
    this.thumbnailVideoUrl = thumbnailVideoUrl;
    this.isTranscoding = isTranscoding;
    this.isPortrait = isPortrait;
    this.isSelectMode = isSelectMode;
    this.isSelected = isSelected;
    this.onSelectChange = onSelectChange;
    this.type = type;
    this.liveSt = liveSt;
    this.thumbnailUrl = thumbnailUrl;
    this.category = category;
    this.isNowLive = isNowLive;
    this.isLocked = isLocked;
    this.duration = duration ?? audioDuration;
    this.audioDuration = audioDuration;
    this.lastSec = lastSec;
    this.viewSec = viewSec;
    this.totalSec = totalSec;
    this.eventStartDt = eventStartDt;
    this.eventEndDt = eventEndDt;
    this.eventStatus = eventStatus;
    this.isLms = isLms;
    this.eduGrpNm = eduGrpNm;
    this.completionYn = completionYn;
    this.eduEndDt = eduEndDt;
    this.isSmall = isSmall;
    this.eduPrg = eduPrg;
    try {
      this.liveStartTime = new Date(Date.parse(liveStartTime));
    } catch (e) {
      try {
        this.liveStartTime = newDate(liveStartTime);
      } catch (e) {}
      this.liveStartTime = null;
      console.error(`[ThumbnailOption] can not parse time ${liveStartTime}`);
    }
  }
}
