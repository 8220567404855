import { useState } from 'react';
import MyPageApi from '@/components/user/my/data/MyPageApi';
import useSubscribe from '@/components/user/my/useSubscribe';

export default function useEarnPointList({ initialPage }) {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(null);
  const [page, setPage] = useState(initialPage);

  const load = async ({ page = 1, size = 9 } = {}) => {
    setPage(page);
    setIsLoading(true);
    let res = await MyPageApi.userEarnPointList({
      _pageNo: page,
      _pageSize: size,
      _searchType: 'plus',
    });
    setList(res.data);
    setTotal(res.total ?? 0);
    setIsLoading(false);
    return res.data;
  };

  return {
    isLoading,
    list,
    total,
    load,
    page,
  };
}
