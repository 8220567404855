import { useSelector } from 'react-redux';

/**
 *
 * @returns {EducationCategory[]}
 */
export default function useEduCategories() {
  const eduCategories = useSelector((state) => state.menuReducer.eduMenuList)
    .data?.data;

  return eduCategories ?? [];
}
