import ThumbnailCard from '../../../components/card/ThumbnailCard';
import { useEffect, useMemo, useState } from 'react';
import PageIndicator from '../../../components/content/paging/PageIndicator';
import MyPageHeader from '@/page/my/MypageHeader';
import useWriteContentSearch from '@/components/user/my/useWriteContentSearch';
import { ContentType } from '@/app/config/ContentType';
import { SearchApi } from '@/components/search/SearchApi';
import { CircularProgress } from '@mui/material';
import { useMyPagePaging } from '@/page/my/tabs/useMyPagePaging';

export default function WroteVOD({}) {
  const [isSelectMode, setSelectMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { isLoading, page, list, load, total, remove } = useWriteContentSearch({
    contentType: ContentType.VOD,
  });

  const { setPage } = useMyPagePaging({ loader: load, page });

  const dataList = useMemo(() => {
    return list?.map((it) =>
      SearchApi.infoToThumbnailOption(it, true, true, true)
    );
  }, [list]);

  return (
    <>
      <MyPageHeader
        option={{
          title: '등록영상',
          count: total,
          selectCount: selectedItems.length,
          isDeleteMode: isSelectMode,
          onEnterDeleteMode: () => setSelectMode(true),
          onCancel: () => {
            setSelectMode(false);
            setSelectedItems([]);
          },
          onAllSelect: () => setSelectedItems(dataList.map((it) => it.cid)),
          onDelete: async () => {
            if (selectedItems.length === 0) {
              return;
            }
            await remove(selectedItems);
            setSelectMode(false);
            setSelectedItems([]);
            load();
          },
        }}
      />
      {isLoading && (
        <div className="w-full flex items-center justify-center min-h-[400px]">
          <CircularProgress className="loading" />
        </div>
      )}
      {!isLoading && (dataList == null || dataList?.length === 0) && (
        <div className="w-full flex items-center justify-center min-h-[400px]">
          <span>등록한 영상이 없습니다.</span>
        </div>
      )}
      {!isLoading && dataList?.length > 0 && (
        <div>
          <div className="box_wrap grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:gap-4 sm:gap-y-12 gap-y-12">
            {dataList.map((item, index) => (
              <div key={`thumbnail_card_${index}`} className="col">
                <ThumbnailCard
                  option={{
                    ...item,
                    id: item.cid,
                    isSelectMode: isSelectMode,
                    author: item.category,
                    isSelected: selectedItems.includes(item.cid),
                    showCounter: false,
                    onSelectChange: (selected) => {
                      if (selected) {
                        setSelectedItems([...selectedItems, item.cid]);
                      } else {
                        setSelectedItems(
                          selectedItems.filter((it) => it !== item.cid)
                        );
                      }
                    },
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {total > 0 && (
        <PageIndicator
          option={{
            page: page - 1,
            totalCount: total,
            pageSize: 9,
            showingPageCount: 5,
            onPageChange: (p) => setPage(p),
          }}
        />
      )}
    </>
  );
}
