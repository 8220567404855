import { apiInstance } from '@/app/config/network';
import LoginUtil from '@/components/user/login/LoginUtil';

export default function useDebugLog() {
  const sendLog = async (logData) => {
    // try {
    //   let userNo = await LoginUtil.getUserId2();
    //   await apiInstance.post('/api/cms/app/log', {
    //     userNo,
    //     logData: JSON.stringify(logData),
    //   });
    // } catch (e) {}
  };

  return {
    sendLog,
  };
}
