import { CircularProgress, Collapse } from '@mui/material';
import { secToMmss } from '@/lib/timeUtil';
import OutlineLabelField from '@/components/form/OutlineLabelField';
import classNames from 'classnames';
import { MobileAuthStatus } from '@/page/join/useMobileAuth';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import useMobileAuth from '@/page/join/useMobileAuth';
import JoinApi from '@/components/join/JoinApi';
import MyPageApi from '@/components/user/my/data/MyPageApi';
import useDebugWeb from '@/components/debug/useDebugWeb';

const MobileAuthField = forwardRef(
  (
    {
      isJoin = false,
      needEmailAuth = false,
      mobileNumber,
      onAuthorized,
      onRefId,
      initAuthState,
    },
    ref
  ) => {
    const {
      setRefId,
      refId,
      authState: mobileAuthState,
      reqMobileCode,
      reqMobileAuth,
      resetAuthState,
      remainTime: mobileRemainTime,
      showAuthInput: showMobileAuthInput,
      isAuthSendLoading: isMobileAuthSendLoading,
      isAuthValidLoading: isMobileAuthValidLoading,
    } = useMobileAuth({
      initAuthState,
      reqApi: isJoin
        ? JoinApi.joinMobileCodeReq
        : MyPageApi.changeMobileCodeReq,
      authApi: isJoin
        ? JoinApi.joinMobileCodeAuth
        : MyPageApi.changeMobileCodeAuth,
    });

    const { sendLog } = useDebugWeb();

    useEffect(() => {
      if (refId != null) {
        onRefId?.(refId);
      }
      setState((s) => ({
        ...s,
        refId: refId,
      }));
    }, [refId]);

    const [state, setState] = useState({
      mobileNumber: mobileNumber,
      authNumber: '',
      errorMessage: null,
      errorMessage2: null,
    });

    const canSendAuth = useMemo(() => {
      return (
        mobileAuthState !== MobileAuthStatus.verified &&
        state.mobileNumber?.length === 11 &&
        (isJoin || state.mobileNumber !== mobileNumber)
      );
    }, [state.mobileNumber]);

    const onChangeMobileNumber = (value) => {
      setState((s) => ({
        ...s,
        mobileNumber: value,
      }));
    };

    const onSendAuth = async () => {
      try {
        setState((s) => ({
          ...s,
          errorMessage: null,
        }));

        if (needEmailAuth) {
          setState((s) => ({
            ...s,
            errorMessage: '이메일 인증을 먼저 진행 해 주세요',
          }));
          return;
        }

        if (mobileNumber != null) {
          if (mobileNumber === state.mobileNumber) {
            setState((s) => ({
              ...s,
              errorMessage: '기존 번호와 동일합니다.',
            }));
            return;
          }
        }

        let res = await reqMobileCode({
          refId: state.refId,
          mobile: state.mobileNumber,
        });
        if (!res) {
          throw new Error('알 수 없는 오류입니다. 잠시 후 다시 시도해주세요.');
        }
      } catch (e) {
        setState((s) => ({
          ...s,
          errorMessage: e.message,
        }));
      }
    };

    const onChangeAuthNumber = (value) => {
      setState((s) => ({
        ...s,
        authNumber: value,
      }));
    };

    const onCheckAuth = async (authNumber) => {
      let param = {
        refId: state.refId,
        mobile: state.mobileNumber,
        authCode: state.authNumber,
      };
      try {
        setState((s) => ({
          ...s,
          errorMessage2: null,
        }));
        let res = await reqMobileAuth(param);
        if (res === true) {
          setState((s) => ({
            ...s,
            errorMessage: null,
            errorMessage2: null,
          }));
          onAuthorized?.(state.mobileNumber);
        }
      } catch (e) {
        setState((s) => ({
          ...s,
          errorMessage2: e.message,
        }));
      }
    };

    useImperativeHandle(ref, () => ({
      validate() {
        console.log('휴대전화 상태', mobileAuthState);
        if (mobileAuthState === MobileAuthStatus.verified) {
          return true;
        } else {
          setState((s) => ({
            ...s,
            errorMessage: '휴대전화 인증을 진행해 주세요',
          }));
          return false;
        }
      },

      get refId() {
        return state.refId;
      },

      set refId(value) {
        setState((s) => ({
          ...s,
          refId: value,
        }));
      },
    }));

    return (
      <_MobileAuthField
        isAuthSendLoading={isMobileAuthSendLoading}
        mobileNumber={state.mobileNumber}
        canSendAuth={canSendAuth}
        showAuthField={showMobileAuthInput}
        authNumber={state.authNumber}
        authRemainTime={mobileRemainTime}
        onChangeMobileNumber={onChangeMobileNumber}
        onSendAuth={onSendAuth}
        onChangeAuthNumber={onChangeAuthNumber}
        onCheckAuth={onCheckAuth}
        errorMessage={state.errorMessage}
        errorMessage2={state.errorMessage2}
        isVerified={mobileAuthState === MobileAuthStatus.verified}
        resetAuthState={resetAuthState}
      />
    );
  }
);

export default MobileAuthField;

function _MobileAuthField({
  isAuthSendLoading,
  mobileNumber,
  onChangeMobileNumber,
  onSendAuth,
  canSendAuth,
  errorMessage,
  showAuthField,
  authNumber,
  onChangeAuthNumber,
  authRemainTime,
  onCheckAuth,
  errorMessage2,
  isVerified,
  resetAuthState,
}) {
  return (
    <>
      <div className="row mb-4">
        <div className="member_input relative">
          <OutlineLabelField
            label="휴대전화번호"
            placeholder="휴대전화번호입력"
            id="userMobile"
            value={mobileNumber}
            disabled={isVerified}
            onChange={onChangeMobileNumber}
            className={classNames({
              error: errorMessage != null,
            })}
            message={errorMessage}
            customButton={
              <button
                type="button"
                className={classNames({
                  'btn_check f14_w300 flex items-center justify-center': true,
                  'pointer-events-none': !canSendAuth,
                })}
                onClick={canSendAuth ? onSendAuth : null}
              >
                {isAuthSendLoading && (
                  <CircularProgress className="loading absolute" />
                )}
                <div
                  className={classNames({
                    f14_w300: true,
                    invisible: isAuthSendLoading,
                    'text-gray-400': !canSendAuth,
                  })}
                >
                  인증하기
                </div>
              </button>
            }
          />
          {isVerified && (
            <button
              type="button"
              className={classNames({
                'btn_check f14_w300 flex items-center justify-center': true,
              })}
              onClick={resetAuthState}
            >
              수정하기
            </button>
          )}
        </div>
      </div>
      <Collapse in={showAuthField}>
        <div className="row mb-4">
          <div className="member_input relative">
            <OutlineLabelField
              label="인증번호"
              placeholder="인증번호입력"
              id="mobileAuth"
              value={authNumber}
              className={classNames({
                error: errorMessage2 != null,
              })}
              message={errorMessage2}
              onChange={onChangeAuthNumber}
              maxLength={6}
              customButton={
                <>
                  {authRemainTime && (
                    <span className="co_orange f12_w300 timer_code">
                      {secToMmss(authRemainTime / 1000)}
                    </span>
                  )}
                  <button
                    type="button"
                    onClick={() => onCheckAuth(authNumber)}
                    className="btn_stroke_m_dis secrit_btn"
                  >
                    확인
                  </button>
                </>
              }
            />
          </div>
        </div>
      </Collapse>
    </>
  );
}
