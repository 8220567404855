import useLogin from '@/components/user/login/useLogin';
import { useEffect, useRef, useState } from 'react';
import Popup from '@/components/popup/Popup';
import Avatar from 'react-avatar-edit';
import MyPageApi, {
  UserProfileRegist,
} from '@/components/user/my/data/MyPageApi';
import LoginUtil from '@/components/user/login/LoginUtil';
import { getTodayPath } from '@/lib/timeUtil';
import useS3FileUpload, {
  FileUploadState,
} from '@/components/content/editor/upload/useS3FileUpload';
import {
  ContentJobStatus,
  ContentJobUpdateFileData,
} from '@/components/content/ContentApi';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import MyProfileImage from '@/components/user/MyProfileImage';

export default function MyInfoProfileImage() {
  const navigator = useNavigate2();
  const { data } = useLogin();
  const [menuOpened, setMenuOpened] = useState(false);
  const userEditProfileImageRef = useRef(null);
  const [userEditProfileImage, setUserEditProfileImage] = useState(null);
  const [avatarPopup, setAvatarPopup] = useState(null);
  const [popup, setPopup] = useState(null);
  const uploader = useS3FileUpload();

  const showErrorPopup = () => {
    setPopup({
      title: '프로필 사진 변경 실패',
      message: '프로필 사진 변경에 실패했습니다.\n다시 시도해주세요',
      okCallback: () => {
        setPopup(null);
      },
    });
  };

  const onProfileSave = async () => {
    if (userEditProfileImageRef.current != null) {
      const fileName = `${new Date().getTime()}.png`;
      let data = userEditProfileImageRef.current.replace(
        /^data:image\/[a-z]+;base64,/,
        ''
      );
      let path = getTodayPath();
      let res = await MyPageApi.profileRegist(
        new UserProfileRegist({
          uploadPath: `${path}/`,
          uploadFile: fileName,
        })
      );
      let resFile = res?.data?.[0];
      console.log(res);
      if (res?.code !== '00' || resFile == null) {
        return showErrorPopup();
      }
      await uploader.uploadBase64(resFile, path, data);
    }
  };

  useEffect(() => {
    if (uploader.state === FileUploadState.uploaded) {
      let fileInfo = uploader.data.fileInfo;
      MyPageApi.profileFileUpdate(
        new ContentJobUpdateFileData({
          ...fileInfo,
          jobGroupSt: ContentJobStatus.working,
          jobGroupPrg: 20,
          jobSt: ContentJobStatus.success,
          jobPrg: 100,
        })
      )
        .then((res) => {
          if (res.code === '00') {
            setPopup({
              title: '프로필 사진 변경 성공',
              message: '프로필 사진이 변경되었습니다.\n잠시 후 적용됩니다.',
              okCallback: () => {
                navigator(0);
              },
            });
          } else {
            showErrorPopup();
          }
        })
        .catch((e) => {
          showErrorPopup();
        });
    }
  }, [uploader.state]);

  useEffect(() => {
    userEditProfileImageRef.current = userEditProfileImage;
  }, [userEditProfileImage]);

  const openProfileEditPopup = () => {
    setAvatarPopup({
      title: '프로필 사진 변경',
      okCallback: async () => {
        try {
          await onProfileSave();
          setAvatarPopup(null);
        } catch (e) {
          return showErrorPopup();
        }
      },
      cancelCallback: () => {
        setAvatarPopup(null);
      },
    });
  };

  return (
    <>
      <MyProfileImage className="w-full h-full" useUserContentLink={false} />
      <div className="relative">
        <button
          className="profile_edit"
          onClick={(e) => {
            e.stopPropagation();
            openProfileEditPopup();
            // setMenuOpened(prevState => !prevState);
          }}
        >
          <img className="" src="/images/profile_edit.png" alt="프로필 수정" />
        </button>
      </div>
      {avatarPopup != null && (
        <Popup {...avatarPopup} className="!w-[unset] max-w-[90vw] pr-10 pl-10">
          <div className="avatar-container">
            <Avatar
              width={300}
              height={200}
              onCrop={(preview) => {
                setUserEditProfileImage(preview);
              }}
            />
          </div>
        </Popup>
      )}
      {popup != null && <Popup {...popup} />}
    </>
  );
}
