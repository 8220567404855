import { useState } from 'react';
import { useTimer } from '@/components/card/thumbnail/useCountDown';
import JoinApi from '@/components/join/JoinApi';
import useAESCrypto from '@/components/user/useAESCrypto';
import useDebugWeb from '@/components/debug/useDebugWeb';

export const MobileAuthStatus = {
  none: 'none',
  // duplicate: 'duplicate',
  sent: 'sent',
  resent: 'resent',
  verified: 'verified',
  expired: 'expired',
  invalid_code: 'invalid_code',
  error: 'error',
};

export default function useMobileAuth({
  initAuthState,
  reqApi = JoinApi.joinMobileCodeReq,
  authApi = JoinApi.joinMobileCodeAuth,
} = {}) {
  const { encrypt } = useAESCrypto();
  const { sendLog } = useDebugWeb();
  const {
    remainTime,
    restart: timerStart,
    clear: timerClear,
    isRunning: isTimerRunning,
  } = useTimer({ time: 3 * 60 * 1000 });

  const [state, setState] = useState({
    refId: null,
    authSendMobile: null,
    authState: initAuthState ?? MobileAuthStatus.none,
    isAuthSendLoading: false,
    isAuthValidLoading: false,
    showAuthInput: false,
  });

  const resetAuthState = () => {
    setState((s) => ({
      ...s,
      authState: MobileAuthStatus.none,
    }));
  };

  const reqMobileCode = async ({ refId, mobile }) => {
    let res;
    let error;
    if (state.authState === MobileAuthStatus.verified) return;
    if (isTimerRunning) {
      if (state.authSendMobile === mobile) {
        // 번호가 바뀌지 않았으면
        throw new MobileAuthError(
          '이미 인증번호가 발송되었습니다. 잠시 기다려주세요'
        );
      }
    }
    let param = {
      refId: refId,
      userMobile: encrypt(mobile),
    };

    try {
      setState((s) => ({
        ...s,
        isAuthSendLoading: true,
      }));
      res = await reqApi(param);
    } catch (e) {
      console.error(e);
      res = e.response;
      error = e.message?.data;
    } finally {
      setState((s) => ({
        ...s,
        isAuthSendLoading: false,
      }));
    }

    // sendLog(
    //   {
    //     type: 'signup_mobile_req_code',
    //     req: param,
    //     res: res,
    //     error: error,
    //   },
    //   state.refId
    // );

    if (error != null) {
      throw new MobileAuthError('인증번호 발송에 실패했습니다.');
    }

    if (res.code !== '00') {
      return false;
    }

    setState((s) => ({
      ...s,
      refId: res.id,
      showAuthInput: true,
      isAuthSendLoading: false,
      authState: MobileAuthStatus.sent,
      authSendMobile: mobile,
    }));
    timerStart();
    return true;
  };

  const reqMobileAuth = async ({ refId, mobile, authCode }) => {
    if (mobile !== state.authSendMobile) {
      throw new MobileAuthError(
        '번호가 변경되었습니다. 인증 번호 발송을 다시해주세요.'
      );
    }
    let res;
    let error;
    let param = {
      refId: refId ?? state.refId,
      authNo: authCode,
    };
    try {
      setState((s) => ({
        ...s,
        isAuthValidLoading: true,
      }));
      res = await authApi(param);
    } catch (e) {
      console.error(e);
      res = e.response;
      error = e.message?.data;
    } finally {
      setState((s) => ({
        ...s,
        isAuthValidLoading: false,
      }));
    }

    sendLog(
      {
        type: 'signup_mobile_auth_code',
        req: param,
        res: res,
        error: error,
      },
      state.refId
    );

    if (error != null) {
      throw new MobileAuthError('인증번호 확인에 실패했습니다.');
    }

    if (res.code !== '00') {
      if (res.code === '98') {
        throw new MobileAuthError('인증번호가 올바르지 않습니다.');
      } else {
        throw new MobileAuthError('인증번호 확인에 실패했습니다.');
      }
    }
    setState((s) => ({
      ...s,
      authState: MobileAuthStatus.verified,
      showAuthInput: false,
    }));

    timerClear();
    return true;
  };

  const setRefId = (refId) => {
    setState((s) => ({
      ...s,
      refId,
    }));
  };

  return {
    setRefId,
    reqMobileCode,
    reqMobileAuth,
    resetAuthState,
    remainTime,
    refId: state.refId,
    authState: state.authState,
    showAuthInput: state.showAuthInput,
    isAuthValidLoading: state.isAuthValidLoading,
    isAuthSendLoading: state.isAuthSendLoading,
  };
}

export class MobileAuthError {
  constructor(message) {
    this.message = message;
  }
}
