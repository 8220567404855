import MyPageApi, {
  UserContentListParam,
} from '@/components/user/my/data/MyPageApi';
import { useMemo } from 'react';
import {
  userContentData,
  userContentMenus,
} from '@/page/ucms/user-content/UserContentContainer';
import useInfiniteQueryWithInitialData from '@/components/infiniteScroll/useInfiniteQueryWithInitialData';

const UploadedVideoQueryKey = {
  all: ['UploadedVideoQueryKey'],
  firstData: (filter) => [...UploadedVideoQueryKey.all, 'firstData', filter],
  filtered: (filter) => [...UploadedVideoQueryKey.all, 'filtered', filter],
};
const UploadedPostQueryKey = {
  all: ['UploadedPostQueryKey'],
  firstData: (filter) => [...UploadedPostQueryKey.all, 'firstData', filter],
  filtered: (filter) => [...UploadedPostQueryKey.all, 'filtered', filter],
};

export function isLastPage(list, pageSize) {
  if (list == null) return false;
  return (list.length ?? 0) < pageSize;
}

const PageWrapper = async (fn, { pageParam }, param, pageFn) => {
  return {
    res: await fn({
      ...param,
      ...(typeof pageFn === 'function'
        ? pageFn(pageParam)
        : { page: pageParam }),
    }),
    nextPage: pageParam + 1,
    prevPage: pageParam - 1,
  };
};
export default function useUserContent({
  userNo,
  page,
  code,
  enabled = false,
}) {
  const uploadedVideoQuery = useInfiniteQueryWithInitialData({
    QueryKeyMap: UploadedVideoQueryKey,
    dataFn: MyPageApi.userContentList,
    initialParam: {
      userNo: Number(userNo),
      searchType: userContentData.uploaded.contentType,
      page,
      size: 8,
    },
    ParamClass: UserContentListParam,
    enabled,
  });

  const uploadedPostQuery = useInfiniteQueryWithInitialData({
    QueryKeyMap: UploadedPostQueryKey,
    dataFn: MyPageApi.userContentList,
    initialParam: {
      userNo: Number(userNo),
      searchType: userContentData.wrote.contentType,
      page,
      size: 6,
    },
    ParamClass: UserContentListParam,
    enabled,
  });

  const totalData = useMemo(() => {
    console.log(uploadedPostQuery, uploadedVideoQuery);
    if (
      !uploadedPostQuery.initLoadCompleted ||
      !uploadedPostQuery.initLoadCompleted
    )
      return {
        nickNm: null,
        datas: [],
      };
    return {
      nickNm: uploadedVideoQuery?.initialDataQuery?.data?.nickNm ?? '',
      datas: [
        {
          ...uploadedVideoQuery,
          ...userContentData.uploaded,
          ...(userContentMenus.find((menu) => menu.code === 'uploaded')
            ?.menuId ?? 1),
          menuCode: 'uploaded',
        },
        {
          ...uploadedPostQuery,
          ...userContentData.wrote,
          ...(userContentMenus.find((menu) => menu.code === 'wrote')?.menuId ??
            1),
          menuCode: 'wrote',
        },
      ],
    };
  }, [
    uploadedVideoQuery.initLoadCompleted,
    uploadedPostQuery.initLoadCompleted,
  ]);

  return {
    uploadedVideoQuery,
    uploadedPostQuery,
    totalData,
  };
}
