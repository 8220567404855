import axios from 'axios';

export default function useDynamicLink() {
  const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;

  /**
   * 즉시 사용 시, not found 오류 발생함
   * @param url
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  const shortenUrl = async (url) => {
    return await axios
      .post(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseApiKey}`,
        {
          longDynamicLink: appUrl(url),
        }
      )
      .then((res) => {
        return res.data?.shortLink;
      });
  };

  const appUrl = (url) => {
    return `https://wbn.page.link/?link=${url}&apn=com.woori.wbn&ibi=com.woori.wbn&isi=6447748194&efr=1`;
  };

  return {
    shortenUrl,
    appUrl,
  };
}
