import LoginEmailInput from '@/components/user/login/LoginEmailInput';
import OutlineLabelField from '@/components/form/OutlineLabelField';
import useAESCrypto from '@/components/user/useAESCrypto';
import { useDispatch } from 'react-redux';
import useSaveLoginId from '@/components/user/login/useSaveLoginId';
import { useState } from 'react';
import useLogin from '@/components/user/login/useLogin';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import { useMutation } from 'react-query';
import MyPageApi from '@/components/user/my/data/MyPageApi';
import Popup from '@/components/popup/Popup';
import usePopup from '@/components/popup/usePopup';
import useCompanyList from '@/components/user/company/useCompanyList';

export default function PasswordForm({ email, onFormChange }) {
  const onIdKeyDown = (e) => {
    if (e.key === 'Enter') {
      document.querySelector('#login_pw').focus();
    }
  };

  const onPwKeyEnter = (e) => {
    if (e.key === 'Enter') {
      handleSendTempPw();
    }
  };

  const [state, setState] = useState({
    id: email,
    name: '',
  });

  const companyList = useCompanyList({ excludeNormal: true });

  let credit = companyList?.filter((it) => it.compId === 13);

  const mutation = useMutation(MyPageApi.resetUserPassword, {
    onSuccess: (res) => {
      if (res.data.code === '00') {
        if (state.id.includes(credit[0].compDomain))
          show({
            title: '안내',
            message: '회원님의 휴대전화로 임시 비밀번호가 전송되었습니다.',
            okCallback: success,
          });
        else
          show({
            title: '안내',
            message: '회원님의 이메일로 임시 비밀번호가 전송되었습니다.',
            okCallback: success,
          });
      } else
        show({
          title: '임시 비밀번호를 전송하지 못했습니다.',
          message: res.data.message,
          okCallback: hide,
        });
    },
  });

  const success = () => {
    hide();
    onFormChange(`login?email=${state.id}`);
  };

  const handleSendTempPw = () => {
    if (mutation.isLoading) {
      return;
    }
    mutation.mutate({
      userId: state.id,
      userNm: state.name,
    });
  };

  const { popup, show, hide } = usePopup();

  return (
    <>
      <div className="login_slide sidebar open">
        <div className="h-[24px]">
          <p className="flex flex-row" onClick={() => onFormChange('login')}>
            <img src="/images/arrow_left.png" alt="닫기" className="close" />
          </p>
        </div>
        <div className="loginIn">
          <img src="/images/logo.png" alt="로고" />
          <h4 className="login_tit">
            우리의 공감소통
            <span className="block">
              미디어 플랫폼 <span className="co_pri">채널W</span>
            </span>
          </h4>
          <p className="s_tit f14_w300 mt-3">
            회원가입시 등록한 이메일 주소를 입력해주세요.
          </p>
          <div className="input_box flex flex-col">
            <LoginEmailInput
              value={state.id}
              onChange={(value) => setState((s) => ({ ...s, id: value }))}
              onKeyDown={onIdKeyDown}
            />
            <div className="mb-4" />
            <OutlineLabelField
              label="성명"
              value={state.name}
              onChange={(value) => setState((s) => ({ ...s, name: value }))}
            />
            <div className="mb-4" />
            <button
              className={mutation.isLoading ? 'pri_btn_dis' : 'pri_btn'}
              type="button"
              onClick={handleSendTempPw}
            >
              임시 비밀번호 보내기
            </button>
          </div>
        </div>
        <span className="copyright f12_w300">ⓒ 2023 WOORI BANK WBN</span>
      </div>
      {popup && <Popup {...popup} />}
    </>
  );
}
