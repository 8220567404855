import MobileDeleteNav from '@/components/footer/MobileDeleteNav';

/**
 *
 * @param {MyPageHeaderOption} option
 * @returns {JSX.Element}
 * @constructor
 */
export default function MyPageHeader({ option }) {
  if (option.isDeleteMode === true) {
    return (
      <>
        <h5 className="mb-6 justify-between flex my_tit_wrap">
          <div className="my_tit">
            선택
            <span className="co_orange ml-2 my_tit_num">
              {option.selectCount ?? 0}
            </span>
          </div>
          <div className="flex gap-6">
            {option.onAllSelect != null && (
              <span className="underline f14_w300" onClick={option.onAllSelect}>
                전체선택
              </span>
            )}
            {option.onCancel != null && (
              <span className="underline f14_w300" onClick={option.onCancel}>
                취소
              </span>
            )}
            {option.onDelete != null && (
              <span className="underline f14_w300" onClick={option.onDelete}>
                삭제
              </span>
            )}
          </div>
        </h5>
        <MobileDeleteNav option={{ ...option }} />
      </>
    );
  }

  return (
    <h5 className="mb-6 justify-between flex my_tit_wrap">
      <div className="my_tit">
        {option.title}
        <span className="co_orange ml-2 my_tit_num">{option.count}</span>
        <div className="co_orange ml-2 my_tit_num inline-block">
          {option.remarks}
        </div>
      </div>
      {option.onEnterDeleteMode != null && (
        <span className="underline f14_w300" onClick={option.onEnterDeleteMode}>
          삭제
        </span>
      )}
    </h5>
  );
}

export class MyPageHeaderOption {
  title;
  count;
  selectCount;
  isDeleteMode;
  onAllSelect;
  onCancel;
  onDelete;
  onEnterDeleteMode;
}
