import Thumbnail, { ThumbnailOption } from './thumbnail/Thumbnail';
import OptionalLinkWrapper from './OptionalLinkWrapper';
import ContentCounter from '@/components/ucms/common/ContentCounter';
import { ContentType } from '@/app/config/ContentType';

/**
 *
 * @param {ThumbnailCardOption} option
 * @returns {JSX.Element}
 * @constructor
 */
export default function ThumbnailCard({ option }) {
  let useLink = option?.useLink;
  // console.log('useLink', option, useLink)

  if (option?.isSelectMode === true) {
    useLink = false;
  } else if (
    option?.isTranscoding === true &&
    option?.ignoreTranscode !== true
  ) {
    useLink = false;
  }

  if (useLink == null) {
    useLink = true;
  }

  return (
    <OptionalLinkWrapper to={useLink ? option?.link : null}>
      <div onClick={option?.onClick} id={`element-${option.cid}`}>
        <Thumbnail option={new ThumbnailOption({ ...option })} />
        <div className="text_info">
          <span className="ch_name">{option?.author}</span>
          {option?.date != null && option?.date?.trim() !== '' && (
            <span className="date">{option?.date}</span>
          )}
        </div>
        <h4 className="info_tit !mb-2">
          {option.isLms === true &&
            option.menuName != null &&
            option.menuName.trim() !== '' && <>[{option.menuName}] </>}
          {option?.title}
        </h4>
        {(option?.showCounter ?? true) && (
          <div>
            <div className="info_icon flex gap-4 items-center">
              <ContentCounter
                isLiked={option?.isLiked}
                viewCount={option?.viewCount}
                likeCount={option?.likeCount}
                shareCount={option?.shareCount}
                commentCount={option?.commentCount}
              />
            </div>
          </div>
        )}
      </div>
    </OptionalLinkWrapper>
  );
}

/**
 * @typedef {Object} ThumbnailCardOption
 * @property {string} thumbnailUrl
 * @property {string} category
 * @property {boolean} isNowLive
 * @property {number} duration
 * @property {Date} liveStartTime
 * @property {string} title
 * @property {string} link
 * @property {string} date
 * @property {boolean} isLiked
 * @property {boolean} isLocked
 * @property {string} author
 * @property {number} commentCount
 * @property {number} viewCount
 * @property {number} likeCount
 * @property {string} type [image, video] image는 1:1 비율 썸네일, video는 16:9 비율 썸네일
 * @property {string} thumbnailVideoUrl
 */
export class ThumbnailCardOption {
  constructor({
    id,
    cid,
    useLink,
    menuId,
    menuName,
    isSelectMode = false,
    isSelected = false,
    ignoreTranscode,
    onSelectChange,
    thumbnailUrl,
    category,
    isNowLive,
    isPortrait = false,
    duration,
    audioDuration,
    liveStartTime,
    title,
    link,
    liveSt,
    date,
    isLiked,
    isLocked,
    tag,
    author,
    commentCount,
    viewCount,
    likeCount,
    shareCount,
    showCounter = true,
    isTranscoding,
    type = 'video',
    thumbnailVideoUrl,
    onClick,
    lastSec,
    viewSec,
    totalSec,
    eventStartDt,
    eventEndDt,
    eventStatus,
    isLms,
    eduGrpNm,
    completionYn,
    eduEndDt,
    eduPrg,
  }) {
    this.id = id;
    this.cid = cid;
    this.menuId = menuId;
    this.menuName = menuName;
    this.useLink = useLink;
    this.thumbnailVideoUrl = thumbnailVideoUrl;
    this.isSelectMode = isSelectMode;
    this.isSelected = isSelected;
    this.ignoreTranscode = ignoreTranscode;
    this.isTranscoding = isTranscoding;
    this.onSelectChange = onSelectChange;
    this.isPortrait = isPortrait;
    this.type = type;
    this.tag = tag;
    this.thumbnailUrl = thumbnailUrl;
    this.category = category;
    this.isNowLive = isNowLive;
    this.isLocked = isLocked;
    this.showCounter = showCounter;
    this.duration = duration ?? audioDuration;
    this.audioDuration = audioDuration;
    this.liveStartTime = liveStartTime;
    this.liveSt = liveSt;
    this.title = title;
    this.link = link;
    this.date = date;
    this.author = author;
    this.isLiked = isLiked;
    this.commentCount = commentCount;
    this.viewCount = viewCount;
    this.likeCount = likeCount;
    this.shareCount = shareCount;
    this.onClick = onClick;
    this.lastSec = lastSec;
    this.viewSec = viewSec;
    this.totalSec = totalSec;
    this.eventStartDt = eventStartDt;
    this.eventEndDt = eventEndDt;
    this.eventStatus = eventStatus;
    this.isLms = isLms;
    this.eduGrpNm = eduGrpNm;
    this.completionYn = completionYn;
    this.eduEndDt = eduEndDt;
    this.eduPrg = eduPrg;
  }
}
