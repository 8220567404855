import MyInfoCard from '../../components/user/my/MyInfoCard';
import { useEffect, useMemo, useState } from 'react';
import ChipTab from '../../components/common/chip/ChipTab';
import { Outlet, useSearchParams } from 'react-router-dom';
import { myInfoMenuMap } from '../../app/config/myinfoMenuMap';
import Config from '../../app/config/config';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import useMenu, { menuType } from '@/components/menu/useMenu';
import useLogin from '@/components/user/login/useLogin';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import { ErrorBoundary } from '@/components/common/ErrorBoundary';
import useEduCategories from '@/components/user/my/useEduCategories';

const eduMenuUrl = 'education';

export default function MyPageContainer({} = {}) {
  const navigate = useNavigate2();
  const { pathname } = useLocation2();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoading, menuList, findMenu } = useMenu();
  const { isLoggedIn, data, isLoading: isLoginLoading } = useLogin();
  const eduMenuList = useEduCategories();

  const _childMenuList = useMemo(() => {
    return menuList
      .filter(
        (it) =>
          it.menuType === menuType.my &&
          it.useYN === 'Y' &&
          it.menuUrl !== '' &&
          it.menuLevel === 2 &&
          it.parentId == Config.myMenuId &&
          myInfoMenuMap[it.menuUrl] != null
      )
      .filter((menu) => menu.menuUrl !== eduMenuUrl || eduMenuList.length > 0)
      .filter(_ttMenuUserAuthFct)
      .sort((a, b) => a.sortSeq - b.sortSeq);
  }, [eduMenuList, menuList]);

  /** 2024.01.15 땡큐토큰 관리 특정 사용자만 표출 */
  function _ttMenuUserAuthFct(menu) {
    if (menu.menuUrl === 'ttmanage') {
      return Config.myMenuTTAuth.find((it) => it === data.userNo) > 0;
    }
    return true;
  }

  if (isLoggedIn === false) {
    window.location.href = '/';
    return '';
  }

  if (isLoading || _childMenuList == null) {
    return '';
  }

  // 선택된 메뉴가 없을 경우 첫 번째 메뉴로 이동
  useEffect(() => {
    const path = pathname.split('/').pop();
    if (path !== 'mypage') return;

    navigate(_childMenuList[0].menuUrl);
  }, []);

  // 교육 영상 메뉴인데 menuId가 없을 경우 첫 번째 메뉴로 이동
  useEffect(() => {
    const path = pathname.split('/').pop();
    if (path !== eduMenuUrl || eduMenuList.length === 0) return;

    const menuId = searchParams.get('menuId');
    if (menuId === 'null' || menuId === 'undefined' || menuId == null) {
      navigate(`${path}?menuId=${eduMenuList[0]?.menuId}&page=0`, {
        replace: true,
      });
    }
  }, [eduMenuList]);

  const navigateWithId = (menuId) => {
    const menuUrl = findMenu(menuId)?.menuUrl;
    const currentMenuUrl = pathname.split('/').pop();
    if (menuUrl === currentMenuUrl) return;

    if (menuUrl != null) {
      setSearchParams({ page: '0' });
      navigate(`${menuUrl}?page=0`, { replace: true });
    }
  };

  const findMenuIdByUrl = () => {
    const url = pathname.split('/').pop();
    const menu = _childMenuList.find((it) => it.menuUrl === url);

    return menu?.menuId;
  };

  const navigateEduMenuWithId = (menuId) => {
    navigate(`${eduMenuUrl}?menuId=${menuId}&page=0`, {
      replace: true,
    });
  };

  const findEduMenuByParams = () => {
    return searchParams.get('menuId');
  };

  return (
    <div id="contents">
      <div className="mypage_wrap pt-12">
        <div className="inner grid grid-cols-4 gap-8 pt-6 lg:pb-12 pb-24">
          <MyInfoCard />
          <div className="grid-cols-3 col-span-4 lg:col-span-3">
            <ChipTab
              align={'start'}
              categories={_childMenuList}
              category={findMenuIdByUrl()}
              setCategory={(id) => {
                navigateWithId(id);
              }}
              pcMb={4}
              mobileMb={0}
            ></ChipTab>
            {pathname.split('/').pop() === eduMenuUrl && (
              <ChipTab
                align={'start'}
                categories={eduMenuList}
                category={findEduMenuByParams()}
                setCategory={(id) => {
                  navigateEduMenuWithId(id);
                }}
              />
            )}
            <div className={'mb-4 xl:6'}></div>

            <ErrorBoundary
              key={`my_page_error_boundary_${pathname}`}
              className="md:!h-[400px]"
            >
              <Outlet />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
}
