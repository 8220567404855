import ThumbnailCard from '../../card/ThumbnailCard';
import { useEffect, useMemo, useRef, useState } from 'react';
import useHomeRecentList from '@/components/content/list/useHomeRecentList';
import { SearchApi } from '@/components/search/SearchApi';
import SortFilter from '@/components/content/list/SortFilter';
import LoadingErrorWrapper from '@/components/ucms/LoadingLastWrapper';
import GridView from '@/components/ucms/GridView';
import usePasswordPopup from '@/components/ucms/common/usePasswordPopup';
import useLogin from '@/components/user/login/useLogin';
import { CircularProgress } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import useInfiniteScroll from '@/components/infiniteScroll/useInfiniteScroll';
import usePage from '@/components/infiniteScroll/usePage';
import useSort from '@/components/infiniteScroll/useSort';

export default function HomeRecentList() {
  const { isLoggedIn } = useLogin();
  const { popup: passwordPopup, onClick } = usePasswordPopup();
  const [sortSelected, setSortSelected] = useState(
    Object.keys(SearchApi.searchSort)[0]
  );

  const [initialPage, _] = usePage();
  const [sort, setSort] = useSort();

  const {
    isLast,
    isLoading,
    list,
    param,
    load,
    loadMore,
    sortChange,
    setPage,
    isInitialLoading,
  } = useHomeRecentList(initialPage);

  const [gridRef, gridBottomRef] = useInfiniteScroll({
    isLoading,
    isLast,
    loadMore,
    setPage,
    isInitLoading: isInitialLoading,
    page: initialPage,
    recoverScroll: true,
  });

  useEffect(() => {
    if (!isInitialLoading) {
      sortChange(SearchApi.searchSort[sort]);
    }
  }, [sort, isLoggedIn]);

  const dataList = useMemo(() => {
    console.log(list);
    return list?.map((it) => SearchApi.infoToThumbnailOption(it, true)); // home recent
  }, [list]);

  const _list = useMemo(() => {
    return dataList.map((it) => ({
      ...it,
      onClick: (e) => {
        onClick(e, it.menuId, it.cid, it.link, it.isLocked);
      },
    }));
  }, [dataList]);

  return (
    <div className="new_cont">
      <div className="inner">
        <div className="cont_tit flex justify-between items-center mb-12 border-b pb-4">
          <h3 className="">최신 콘텐츠</h3>
          <SortFilter selected={sort} onSelect={setSort} />
        </div>
        <LoadingErrorWrapper
          isLoading={isLoading}
          isLast={isLast && list.length !== 0}
          lastContentMessage="마지막 영상입니다."
        >
          <div ref={gridRef}>
            <GridView
              dataList={_list}
              rowCount={12}
              columnCount={4}
              parentClassName="
                            lg:gap-y-16
                            lg:mb-16
                            gap-4
                            gap-y-12
                            mb-12
                            grid
                            lg:grid-cols-4
                            sm:grid-cols-2
                        "
              itemRender={(item, i) => (
                <div
                  id={`element-${item.cid}`}
                  key={`home_thumbnail_card_${item.link}_${i}`}
                  className="col"
                >
                  <ThumbnailCard option={item} />
                </div>
              )}
            />
          </div>
          <div ref={gridBottomRef} />
        </LoadingErrorWrapper>
      </div>
      {passwordPopup}
    </div>
  );
}
