import useLocation2 from '../../components/sidebar/hook/useLocation2';
import OutlineLabelField from '../../components/form/OutlineLabelField';
import { useEffect, useMemo, useRef, useState } from 'react';
import Config from '../../app/config/config';
import TermCheck from '../../components/form/TermCheck';
import JoinPrivacyOptionalTermPopup from './JoinPrivacyOptionalTermPopup';
import JoinPrivacyMustTermPopupForNormal from './JoinPrivacyMustTermPopupForNormal';
import {
  validateTerms,
  validPassword,
  validPassword2,
} from '../../lib/signupUtil';
import useNicknameValidate from '../../components/form/useNicknameValidate';
import JoinApi from '@/components/join/JoinApi';
import useCompanyList from '@/components/user/company/useCompanyList';
import Popup from '@/components/popup/Popup';
import Link2 from '@/components/common/transition/Link2';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import { Collapse } from '@mui/material';
import { ValidatorInput } from '@/page/my/tabs/MyInfoEdit';
import {
  EmpNo8Validator,
  EmpNoValidator,
  NameValidator,
  RankNmValidator,
  useRegexArrayValidator,
} from '@/components/form/useRegexValidate';
import useAESCrypto from '@/components/user/useAESCrypto';
import useQuery from '@/components/sidebar/hook/useQuery';
import JoinPrivacyThirdPartyTermPopup from '@/page/join/JoinPrivacyThirdPartyTermPopup';
import MobileAuthField from '@/components/form/MobileAuthField';
import EmailAuthField from '@/components/form/EmailAuthField';
import useDeptList from '@/components/user/company/useDeptList';
import useRankList from '@/components/user/company/useRankList';
import SearchableDropdown from '@/components/common/SearchableDropdown';
import useLogin from '@/components/user/login/useLogin';
import JoinPrivacyMustTermPopupForEmployee from '@/page/join/JoinPrivacyMustTermPopupForEmployee';
import usePositionList from '@/components/user/company/usePositionList';
import classNames from 'classnames';
import useDebugWeb from '@/components/debug/useDebugWeb';

export default function JoinFormPage() {
  const { sendLog } = useDebugWeb();
  const { isLoggedIn } = useLogin();
  const { showPrivacy } = useQuery();
  const { encrypt } = useAESCrypto();
  const { pathname } = useLocation2();
  const navigator = useNavigate2();
  const mobileAuthRef = useRef();
  const emailAuthRef = useRef();

  const isCo = pathname === '/join/form/co';
  const [showPrivacyMustPopup, setShowPrivacyMustPopup] = useState(false);
  const [showPrivacyOptionalPopup, setShowPrivacyOptionalPopup] =
    useState(false);
  const [showThirdPrivacyPopup, setShowThirdPrivacyPopup] = useState(false);
  const [formData, setFormData] = useState({});
  const companyList = useCompanyList({ excludeNormal: true });
  const [popup, setPopup] = useState(null);
  const [isSignupLoading, setIsSignupLoading] = useState(false);
  // const [refId, setRefId] = useState(null);

  useEffect(() => {
    if (isLoggedIn) {
      navigator('/');
    }
  }, [isLoggedIn]);

  const getCompany = (email) => {
    return companyList.find((it) => email.includes(`@${it.compDomain}`));
  };

  const {
    message: nicknameMessage,
    value: nickname,
    isValidate: isValidNickname,
    setValue: setNickname,
    validate: nicknameValidate,
  } = useNicknameValidate(formData.nick);

  useEffect(() => {
    if (showPrivacy === 'true') {
      setShowPrivacyOptionalPopup(true);
    }
  }, [showPrivacy]);

  const updateData = (key, value) => {
    setFormData((s) => ({
      ...s,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (formData.pw1 != null) {
      let { message } = validPassword(formData.pw1);
      updateData('pw1Message', message);
    }
  }, [formData.pw1]);

  useEffect(() => {
    if (formData.pw2 != null) {
      let { message } = validPassword2(formData.pw1, formData.pw2);
      updateData('pw2Message', message);
    }
  }, [formData.pw2]);

  const onEmailRefId = (refId) => {
    if (mobileAuthRef.current?.refId !== refId) {
      if (mobileAuthRef.current != null) {
        mobileAuthRef.current.refId = refId;
      }
    }
    updateData('refId', refId);
  };

  const onMobileRefId = (refId) => {
    if (emailAuthRef.current?.refId !== refId) {
      if (emailAuthRef.current != null) {
        emailAuthRef.current.refId = refId;
      }
    }
    updateData('refId', refId);
  };

  const handleSignupWithLoading = async () => {
    if (isSignupLoading) {
      return;
    }
    setIsSignupLoading(true);
    try {
      await handleSignup();
    } catch (e) {}
    setIsSignupLoading(false);
  };

  const handleSignup = async () => {
    let validList = [
      {
        key: 'pw1Message',
        ...validPassword(formData.pw1),
      },
      {
        key: 'pw2Message',
        ...validPassword2(formData.pw1, formData.pw2),
      },
      {
        key: 'nickMessage',
        message: nicknameMessage,
        isValid: isValidNickname,
      },
      {
        key: 'termMessage',
        ...validateTerms(formData),
      },

      // {
      //   key: 'empNoMessage',
      //   ...empNoValidator,
      // },
    ];

    // if (!isCo) {
    //   validList.push({
    //     key: 'userCompMessage',
    //     ...validUserComp(formData.userComp ?? ''),
    //   });
    // }

    validList.forEach((it) => {
      updateData(it.key, it.message);
    });

    let _isValidEmail = emailAuthRef.current?.validate() === true;
    let _isValidName = await nameValidator.validate();
    let _isValidNickname = await nicknameValidate();
    let _isValidMobile = mobileAuthRef.current?.validate() === true;
    let _isValidRankNm = true; //await rankNmValidator.validate();
    let _isValidEmpNo = isCo ? await empNoValidator.validate() : true;
    let _isValidDeptCd = isCo
      ? deptCd?.value != null && deptCd?.value !== ''
      : true;
    let _isValidRankTypeCd = useRankTypeCd
      ? rankTypeCd?.value != null && rankTypeCd?.value !== ''
      : true;

    let _isValidPositionCd = isCo
      ? positionCd?.value != null && positionCd?.value !== ''
      : true;

    if (!_isValidDeptCd) {
      updateData('deptCdMessage', '부서를 선택해 주세요');
    } else {
      updateData('deptCdMessage', null);
    }

    if (!_isValidRankTypeCd) {
      updateData('rankTypeCdMessage', '직급분류를 선택해 주세요');
    } else {
      updateData('rankTypeCdMessage', null);
    }

    if (!_isValidPositionCd) {
      updateData('positionCdMessage', '직위를 선택해 주세요');
    } else {
      updateData('positionCdMessage', null);
    }

    const notValidField = validList.find((it) => it.isValid === false);

    // console.log(
    //   _isValidName,
    //   _isValidEmail,
    //   _isValidNickname,
    //   _isValidMobile,
    //   _isValidRankNm,
    //   _isValidEmpNo,
    //   _isValidDeptCd,
    //   _isValidRankTypeCd,
    //   _isValidPositionCd,
    //   notValidField
    // );

    if (
      (notValidField != null && notValidField.key !== 'userCompMessage') ||
      // || emailValue == null
      // || emailValue === ''
      // || nickname == null
      // || nickname === ''
      !_isValidName ||
      !_isValidEmail ||
      !_isValidNickname ||
      !_isValidMobile ||
      !_isValidRankNm ||
      !_isValidEmpNo ||
      !_isValidDeptCd ||
      !_isValidRankTypeCd ||
      !_isValidPositionCd
    ) {
      return;
    }

    let userComp = null;
    let compId = 0;
    if (isCo) {
      let company = getCompany(emailAuthRef.current.email);
      if (company == null) {
        setPopup({
          title: '오류',
          message: '알 수 없는 임직원 메일 입니다.',
          okCallback: () => {
            setPopup(null);
          },
        });
        return;
      }
      compId = company.compId;
      userComp = company.compNm;
    } else {
      userComp = formData.userComp;
    }

    submit(compId, userComp);
  };

  const [email, setEmail] = useState('');
  const compCd = useMemo(() => getCompany(email)?.wbnCompCd, [email]);
  const deptQuery = useDeptList(compCd);
  const rankQuery = useRankList(compCd);
  const { positionQuery, useRankTypeCd } = usePositionList(compCd);
  const [deptCd, setDeptCd] = useState();
  const [positionCd, setPositionCd] = useState();
  const [rankTypeCd, setRankTypeCd] = useState();

  useEffect(() => {
    // setDeptCd('');
  }, [compCd]);

  useEffect(() => {
    if (rankTypeCd != null) {
      updateData('rankTypeCdMessage', null);
    }
  }, [rankTypeCd]);

  useEffect(() => {
    if (positionCd != null) {
      updateData('positionCd', null);
    }
  }, [positionCd]);

  useEffect(() => {
    if (deptCd != null) {
      updateData('deptCdMessage', null);
    }
  }, [deptCd]);

  const submit = async (compId, userComp) => {
    let param = {
      refId: formData.refId,
      userPwd: encrypt(formData.pw1),
      nickNm: nickname.trim(),
      userNm: nameValidator.value.trim(),
      userComp,
      appOS: '',
      appVersion: '',
      appToken: '',
      userRank: '',
      agree1Yn: formData.termPrivacyMust ? 'Y' : 'N',
      agree2Yn: formData.termPrivacyOptional ? 'Y' : 'N',
      agree3Yn: formData.termPrivacyThirdParty ? 'Y' : 'N',
      wbnCompCd: compCd,
      wbnDeptCd: !isCo ? '' : deptCd.value,
      wbnRankTypeCd: !isCo
        ? ''
        : useRankTypeCd
        ? rankTypeCd.value
        : positionCd.value,
      wbnRankNm: !isCo ? '' : rankNmValidator.value,
      wbnEmpNo: !isCo ? '' : empNoValidator.value,
      wbnPositionCd: !isCo ? '' : positionCd.value,
    };
    let res;
    let error;
    try {
      res = await JoinApi.signup(param);

      const data = res.data;
      res = data;
      const code = data.code;
      const message =
        data.message != null && data.message?.trim() !== ''
          ? data.message
          : '회원가입에 오류가 발생하였습니다.';

      if (code === '00') {
        // navigator(`/join/emailAuth/${emailValidator.value}`);
        navigator(`/?signup=${isCo ? 'co' : 'normal'}`);
      } else if (code === '71') {
        setPopup({
          title: '안내',
          message: message,
          okCallback: () => {
            setPopup(null);
          },
        });
      } else if (code === '93') {
        setPopup({
          title: '안내',
          message: message,
          okCallback: () => {
            setPopup(null);
          },
        });
      } else {
        setPopup({
          title: '가입 오류',
          message: message,
          okCallback: () => {
            setPopup(null);
          },
        });
      }
    } catch (e) {
      res = e.response?.data;
      error = e.message;
    }
    // sendLog(
    //   {
    //     type: 'signup',
    //     req: {
    //       ...param,
    //       userPwd: param?.userPwd?.replace(/./g, '*'),
    //     },
    //     res: res,
    //     error: error,
    //   },
    //   formData.refId
    // );
  };

  const nameValidator = useRegexArrayValidator(NameValidator, '', null);
  const rankNmValidator = useRegexArrayValidator(RankNmValidator, '', null);
  const empNoValidator = useRegexArrayValidator(
    useRankTypeCd ? EmpNo8Validator : EmpNoValidator,
    '',
    null
  );

  useEffect(() => {
    empNoValidator.validate();
  }, [useRankTypeCd]);

  return (
    <div id="contents">
      <div className="join_cont flex justify-center items-center">
        <div className="inner_box">
          <div className="join_box">
            <h5 className="head_tit mb-4 justify-between">
              <Link2 to={-1}>
                <img
                  className="arrow_left"
                  src="/images/arrow_left.png"
                  alt="뒤로가기"
                />
              </Link2>
              <span>회원가입</span>
              <span></span>
            </h5>
            {isCo && (
              <>
                <p className="f14_w300 mb-2">우리금융그룹</p>
                <h4 className="join_tit f28_w700 mb-8"> 임직원 회원정보</h4>
              </>
            )}
            {!isCo && (
              <h4 className="join_tit f28_w700 mb-8"> 일반 회원정보</h4>
            )}
            <div className="input_box flex flex-col mb-4">
              <EmailAuthField
                onChangeValue={setEmail}
                ref={emailAuthRef}
                onAuthorized={() => {
                  updateData('emailAuthorized', true);
                }}
                isCo={isCo}
                onRefId={onEmailRefId}
              />

              <OutlineLabelField
                label="비밀번호"
                type="password"
                name="new-password"
                autoComplete="on"
                placeholder="8자 이상, 영문, 숫자, 특수문자"
                value={formData.pw1}
                onChange={(value) =>
                  updateData(
                    'pw1',
                    value.substring(0, Config.passwordMaxLength)
                  )
                }
                className={formData.pw1Message != null ? 'error' : ''}
                message={formData.pw1Message}
              />
              <div className="mb-4" />

              <OutlineLabelField
                label="비밀번호 확인"
                type="password"
                placeholder="8자 이상, 영문, 숫자, 특수문자"
                value={formData.pw2}
                onChange={(value) =>
                  updateData(
                    'pw2',
                    value.substring(0, Config.passwordMaxLength)
                  )
                }
                className={formData.pw2Message != null ? 'error' : ''}
                message={formData.pw2Message}
              />
              <div className="mb-4" />

              <OutlineLabelField
                label="닉네임"
                placeholder="3 ~ 10자 사이"
                value={nickname}
                onChange={(value) => setNickname(value)}
                customButton={
                  <button
                    type="button"
                    className="btn_check f14_w300 flex items-center justify-center"
                    onClick={nicknameValidate}
                  >
                    중복확인
                  </button>
                }
                className={
                  nicknameMessage != null && !isValidNickname ? 'error' : ''
                }
                message={nicknameMessage}
              />
              <div className="mb-4" />

              <ValidatorInput label="성명" validator={nameValidator} />
              <div className="mb-4" />
              {!isCo && (
                <div className="row flex mb-4">
                  <div className="member_input relative w-full">
                    <OutlineLabelField
                      label="직업"
                      id="userComp"
                      value={formData.userComp}
                      onChange={(v) => updateData('userComp', v)}
                      message={formData.userCompMessage}
                      className={formData.userCompMessage ? 'error' : ''}
                    />
                  </div>
                </div>
              )}
            </div>

            {isCo && (
              <>
                <div className="row flex mb-4">
                  <div className="member_input relative flex-1">
                    <SearchableDropdown
                      id={`${compCd}_deptCd`}
                      label="부서명"
                      noOptionsText={'결과가 없습니다.'}
                      searchQuery={{
                        ...deptQuery,
                        data: [
                          ...(deptQuery?.data?.map((item) => ({
                            name: item.deptNm,
                            value: item.deptCd,
                          })) ?? []),
                        ],
                      }}
                      className={
                        formData.deptCdMessage != null &&
                        formData.deptCdMessage !== ''
                          ? 'error'
                          : ''
                      }
                      message={formData.deptCdMessage}
                      selectedItem={deptCd}
                      onSelectItem={setDeptCd}
                    />
                  </div>
                </div>
                <Collapse in={useRankTypeCd}>
                  <div className="row flex  mb-4">
                    <div className="member_input relative flex-1">
                      <SearchableDropdown
                        id={`${compCd}_positionCd`}
                        label="직위"
                        noOptionsText={'결과가 없습니다.'}
                        searchQuery={{
                          ...rankQuery,
                          data:
                            rankQuery?.data?.map((item) => ({
                              name: item.rankTypeNm,
                              value: item.rankTypeCd,
                            })) ?? [],
                        }}
                        className={
                          formData.rankTypeCdMessage != null &&
                          formData.rankTypeCdMessage !== ''
                            ? 'error'
                            : ''
                        }
                        message={formData.rankTypeCdMessage}
                        selectedItem={rankTypeCd}
                        onSelectItem={setRankTypeCd}
                      />
                    </div>
                  </div>
                </Collapse>
                <div className="row flex  mb-4">
                  <div className="member_input relative flex-1">
                    <SearchableDropdown
                      id={`${compCd}_rankTypeCd`}
                      label={useRankTypeCd ? '직급분류' : '직위'}
                      noOptionsText={'결과가 없습니다.'}
                      openOnlyOnText={!useRankTypeCd}
                      searchQuery={{
                        ...positionQuery,
                        data:
                          positionQuery?.data?.map((item) => ({
                            name: item.positionNm,
                            value: item.positionCd,
                          })) ?? [],
                      }}
                      className={
                        formData.positionCdMessage != null &&
                        formData.positionCdMessage !== ''
                          ? 'error'
                          : ''
                      }
                      message={formData.positionCdMessage}
                      selectedItem={positionCd}
                      onSelectItem={setPositionCd}
                    />
                  </div>
                </div>
                <div className="row flex  mb-4">
                  <div className="member_input relative flex-1">
                    <ValidatorInput
                      label="사번(행번)"
                      type="text"
                      validator={empNoValidator}
                      // message={formData.empNoMessage}
                    />
                  </div>
                </div>
              </>
            )}
            <MobileAuthField
              ref={mobileAuthRef}
              isJoin={true}
              needEmailAuth={
                formData.refId == null || formData.emailAuthorized !== true
              }
              onRefId={onMobileRefId}
              onAuthorized={(value) => {
                updateData('userMobile', value);
              }}
            />
            <div className="info_box">
              <div className="w-full all_agree_wrap mb-3 pb-3 border-b">
                <input
                  id="all_agree2"
                  className="id_save"
                  type="checkbox"
                  checked={
                    formData.termPrivacyMust &&
                    formData.termPrivacyOptional &&
                    formData.termPrivacyThirdParty
                  }
                  onChange={(e) => {
                    updateData('termPrivacyMust', e.target.checked);
                    updateData('termPrivacyOptional', e.target.checked);
                    updateData('termPrivacyThirdParty', e.target.checked);
                  }}
                />

                <label htmlFor="all_agree2" className="aree_text">
                  전체 동의
                </label>

                <div className="outline_field error">
                  <Collapse
                    in={
                      formData.termMessage != null && !formData.termPrivacyMust
                    }
                  >
                    <div
                      className={'outline_field_message error'}
                      style={{ height: '100%', padding: '10px 16px' }}
                    >
                      {formData.termMessage}
                    </div>
                  </Collapse>
                </div>
              </div>
              <TermCheck
                id={'termPrivacyMust'}
                text="개인정보 수집·이용 동의(필수)"
                checked={formData.termPrivacyMust}
                onChange={(value) => updateData('termPrivacyMust', value)}
                onDetail={() => setShowPrivacyMustPopup(true)}
              />
              <TermCheck
                id={'termPrivacyOptional'}
                text="개인정보 수집·이용 동의(선택)"
                checked={formData.termPrivacyOptional}
                onChange={(value) => {
                  updateData('termPrivacyOptional', value);
                }}
                onDetail={() => setShowPrivacyOptionalPopup(true)}
              />

              <TermCheck
                id={'termPrivacyThirdParty'}
                text="개인정보 제3자 제공 동의(선택)"
                checked={formData.termPrivacyThirdParty}
                onChange={(value) => {
                  updateData('termPrivacyThirdParty', value);
                }}
                onDetail={() => setShowThirdPrivacyPopup(true)}
              />
            </div>
            <button
              type="submit"
              className={classNames({
                'mb-4': true,
                pri_btn: !isSignupLoading,
                pri_btn_dis: isSignupLoading,
                'cursor-default': isSignupLoading,
              })}
              onClick={handleSignupWithLoading}
            >
              회원가입
            </button>
          </div>
        </div>

        <div id="term_detail_portal" className="absolute" />
      </div>

      {showPrivacyMustPopup && (
        <>
          {isCo && (
            <JoinPrivacyMustTermPopupForEmployee
              onAgree={(agree) => updateData('termPrivacyMust', agree)}
              onClose={() => setShowPrivacyMustPopup(false)}
            />
          )}
          {!isCo && (
            <JoinPrivacyMustTermPopupForNormal
              onAgree={(agree) => updateData('termPrivacyMust', agree)}
              onClose={() => setShowPrivacyMustPopup(false)}
            />
          )}
        </>
      )}
      {showPrivacyOptionalPopup && (
        <JoinPrivacyOptionalTermPopup
          onAgree={(agree) => updateData('termPrivacyOptional', agree)}
          onClose={() => setShowPrivacyOptionalPopup(false)}
        />
      )}
      {showThirdPrivacyPopup && (
        <JoinPrivacyThirdPartyTermPopup
          onAgree={(agree) => updateData('termPrivacyThirdParty', agree)}
          onClose={() => setShowThirdPrivacyPopup(false)}
        />
      )}
      {popup != null && <Popup {...popup} />}
    </div>
  );
}
