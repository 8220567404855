import { useEffect, useMemo, useState } from 'react';
import MyPageHeader from '@/page/my/MypageHeader';
import ThumbnailCard from '@/components/card/ThumbnailCard';
import { SearchApi } from '@/components/search/SearchApi';
import { useSearchParams } from 'react-router-dom';
import PageIndicator from '@/components/content/paging/PageIndicator';
import useEduVideos from '@/components/user/my/useEduVideos';
import { useMyPagePaging } from '@/page/my/tabs/useMyPagePaging';
import { CircularProgress } from '@mui/material';
import useEduCategories from '@/components/user/my/useEduCategories';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import { getEduMenuList } from '@/components/menu/MenuReducer';
import { useDispatch } from 'react-redux';
import MyPageApi from '@/components/user/my/data/MyPageApi';

export default function EducationVideo({}) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation2();
  const menuId = searchParams.get('menuId');
  const [selectedItems, setSelectedItems] = useState([]);
  const { isLoading, page, list, load, total } = useEduVideos();
  const eduMenuList = useEduCategories();
  const [menu, setMenu] = useState({
    name: '필수영상',
    completeCount: 0,
    allCount: 0,
    remarks: '',
  });

  /**
   * 서버 조회로 메뉴 정보 업데이트
   * @param menuId
   * @returns {Promise<void>}
   */
  const updateInfo = async (menuId) => {
    try {
      let res = await MyPageApi.getEducationCategories();
      let data = res.data;
      let menu = data.find((it) => it.menuId == menuId);
      setMenu({
        name: menu.menuNm,
        completeCount: menu.completeCnt,
        allCount: menu.allCnt,
        remarks: menu.remarks,
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!isNaN(parseInt(menuId))) {
      load({ menuId });
    }
  }, [menuId]);

  useEffect(() => {
    if (isNaN(parseInt(menuId))) return;

    const currentMenu = eduMenuList.find((it) => it.menuId == menuId);
    if (currentMenu != null) {
      setMenu({
        name: currentMenu.menuNm,
        completeCount: currentMenu.completeCnt,
        allCount: currentMenu.allCnt,
        remarks: currentMenu.remarks,
      });
    }
    updateInfo(menuId);
  }, [eduMenuList, menuId]);

  const { setPage } = useMyPagePaging({
    loader: (pageInfo) => load({ ...pageInfo, menuId: menuId }),
    page: page,
    isStartFromOne: true,
    size: 9,
  });

  const dataList = useMemo(() => {
    return list?.map((it) => SearchApi.infoToThumbnailOption(it, false, true));
  }, [list]);

  return (
    <>
      <MyPageHeader
        option={{
          title: menu.name,
          count: `( ${menu.completeCount} / ${menu.allCount} )`,
          remarks: menu.remarks,
          selectCount: selectedItems.length,
          isDeleteMode: false,
          onCancel: () => {
            setSelectedItems([]);
          },
          onAllSelect: () => setSelectedItems(),
          onDelete: async () => {
            if (selectedItems.length === 0) {
              return;
            }
            setSelectedItems([]);
          },
        }}
      />
      {isLoading && (
        <div className="h-72 w-full flex items-center justify-center">
          <CircularProgress className="loading" />
        </div>
      )}
      {!isLoading && (dataList == null || dataList?.length === 0) && (
        <div className="w-full flex items-center justify-center min-h-[400px]">
          <span>{menu?.name ?? '항목'}이 없습니다.</span>
        </div>
      )}
      {!isLoading && (
        <div className="box_wrap grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:gap-4 sm:gap-y-12 gap-y-12">
          {dataList?.length > 0 &&
            dataList.map((item, index) => (
              <div key={`thumbnail_card_${index}`} className="col">
                <ThumbnailCard
                  option={{
                    ...item,
                    id: item.cid,
                    isSelectMode: false,
                    author: item.category,
                    showCounter: false,
                  }}
                />
              </div>
            ))}
        </div>
      )}
      {total > 0 && (
        <PageIndicator
          option={{
            page: page - 1,
            totalCount: total,
            pageSize: 9,
            showingPageCount: 5,
            onPageChange: (p) => setPage(p),
          }}
        />
      )}
    </>
  );
}
