import Chip from './chip';
import HorizontalCarousel from '../../ucms/common/HorizontalCarousel';
import { useEffect, useRef } from 'react';

export default function ChipTab({
  categories,
  category,
  setCategory,
  align = 'center',
  pcMb = 10,
  mobileMb = 4,
  children,
}) {
  return (
    <>
      {categories.length > 0 && (
        <>
          <ul
            className={`slide-wrap flex flex-wrap xl:justify-${align} justify-start gap-4 mb-${pcMb}`}
          >
            {categories.map((item) => (
              <Chip
                key={`${item.menuId}`}
                checked={category == item.menuId}
                onChecked={(value) => {
                  setCategory(value);
                }}
                label={item.menuNm}
                value={item.menuId}
              />
            ))}
            {children}
          </ul>
          <ul
            className={`slide-wrap_m button_slider flex flex-nowrap xl:justify-center justify-start gap-4 mb-${mobileMb}`}
          >
            <HorizontalCarousel
              items={categories}
              renderItem={(item) => (
                <Chip
                  key={item.menuId}
                  checked={category == item.menuId}
                  onChecked={(value) => {
                    setCategory(value);
                  }}
                  label={item.menuNm}
                  value={item.menuId}
                />
              )}
            >
              {children}
            </HorizontalCarousel>
          </ul>
        </>
      )}
    </>
  );
}
