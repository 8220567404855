import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Home from '../../page/home/Home';
import BoardEditor from '../../components/editor/BoardEditor';
import Container from '../../components/layout/Container';
import TestMain from '../../page/test/TestMain';
import JoinTypePage from '../../page/join/JoinType';
import JoinFormPage from '../../page/join/JoinFormPage';
import JoinEmailAuthPage from '../../page/join/JoinEmailAuth';
import FormTestPage from '../../page/test/FormTestPage';
import UcmsBoardContainer from '../../page/ucms/UcmsBoardContainer';
import { myContainerMap, myInfoMenuMap } from './myinfoMenuMap';
import Config from './config';
import ContentEditor, {
  EditorData,
} from '../../components/content/editor/ContentEditor';
import ContentWrite from '@/page/ucms/ContentWrite';
import SearchPage from '@/page/search/SearchPage';
import PlayerTestPage from '@/page/test/PlayerTestPage';
import CryptoTestPage from '@/page/test/CryptoTestPage';
import useMenu, { menuType } from '@/components/menu/useMenu';
import UcmsDetailContainer from '@/page/ucms/UcmsDetailContainer';
import UcmsBoard from '@/page/ucms/UcmsBoard';
import AlertGridContainer from '@/page/my/AlertGridContainer';
import VideoEmbedPage from '@/page/embed/VideoEmbedPage';
import ScrollToTop from '@/components/common/ScrollToTop';
import DownloadPage from '@/page/etc/DownloadPage';
import { useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import LoginContainer from '@/components/layout/LoginContainer';
import UserContentContainer from '@/page/ucms/user-content/UserContentContainer';
import PortalContainer from '@/components/layout/PortalContainer';
import MyTermUpdatePage from '@/page/my/MyTermUpdate';
import PrivacyTermPage from '@/page/term/PrivacyTermPage';
import ThankYouTokenRoutePage from '@/page/thankyoutoken/ThankYouTokenRoutePage';
import PinTestPage from '@/components/easyLogin/pin/test';
import EasyPinLoginContainer from '@/components/layout/EasyPinLoginContainer';
import BioTestPage from '@/components/easyLogin/bio/test';

export default function AppRoute() {
  const { menuList, rootMenuList, isLoading } = useMenu();

  /**
   * 마이 메뉴 관련 라우팅 생성
   * Config.myMenuId, Config.myInfoId, Config.myPointId 메뉴를 찾아,
   * myContainerMap에서 Container를 가져와 각 하위 메뉴를 myInfoMenuMap 에서 찾아 할당.
   * 모든 메뉴는 menuUrl이 있어야 함.
   *
   * @param parentMenuId
   * @returns {JSX.Element|string}
   */
  const createMyRoute = (parentMenuId) => {
    const parentMenu = menuList.find(
      (it) => it.menuId == parentMenuId && it.useYN === 'Y'
    );

    if (parentMenu == null) {
      return '';
    }

    const targetList = menuList
      .filter(
        (it) =>
          it.menuType === menuType.my &&
          it.useYN === 'Y' &&
          it.menuUrl !== '' &&
          it.parentId == parentMenuId &&
          it.menuLevel === 2 &&
          myInfoMenuMap[it.menuUrl] != null
      )
      .sort((a, b) => a.sortSeq - b.sortSeq);

    const container = myContainerMap[parentMenu.menuUrl];

    // console.log(parentMenu.menuUrl, menuList.map(it => it.menuUrl).join(","))

    return (
      <Route
        key={parentMenu.menuUrl}
        path={`/${parentMenu.menuUrl}`}
        element={container}
      >
        {targetList.map((it) => (
          <Route
            index
            key={it.menuUrl}
            path={it.menuUrl}
            element={myInfoMenuMap[it.menuUrl]}
          />
        ))}
      </Route>
    );
  };

  const myMenuRoute = createMyRoute(Config.myMenuId);
  const myInfoRoute = createMyRoute(Config.myInfoId);
  const myPointRoute = createMyRoute(Config.myPointId);
  const myFollowRoute = createMyRoute(Config.myFollowId);
  const myAffltRoute = createMyRoute(Config.myAffltId);

  const routes = useMemo(() => {
    return !isLoading && menuList != null
      ? [myMenuRoute, myInfoRoute, myPointRoute, myFollowRoute, myAffltRoute]
      : null;
  }, [isLoading]);

  if (routes == null)
    return (
      <div className="h-[100vh] w-full flex items-center justify-center">
        <CircularProgress className="loading" />
      </div>
    );

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="" element={<PortalContainer />}>
          <Route path="" element={<EasyPinLoginContainer />}>
            <Route path="" element={<LoginContainer />}>
              {/*랜딩 임시*/}
              {/*<Route path="/" element={<LandingHome/>}/>*/}
              {/*랜딩 임시*/}
              {/*<Route path="/landing" element={<LandingHome/>}/>*/}
              <Route path="" element={<Container />}>
                {/*랜딩 임시*/}
                <Route path="" element={<Home />} />
                {/*랜딩 임시*/}
                {/*<Route path="/home" element={<Home/>}/>*/}

                <Route path="/search/:keyword" element={<SearchPage />} />
                <Route
                  path="/user/content"
                  element={<UserContentContainer />}
                />
                <Route
                  path="/thankyoutoken/*"
                  element={<ThankYouTokenRoutePage />}
                />

                <Route path="/ucms" element={<UcmsBoardContainer />}>
                  <Route path=":menuId" element={<UcmsBoard />} />
                  <Route path=":menuId/:menuId2" element={<UcmsBoard />} />
                </Route>
                <Route
                  path="/ucms/view/:menuId/:id"
                  element={<UcmsDetailContainer />}
                />
                <Route path="/ucms/write/:menuId" element={<ContentWrite />} />
                <Route
                  path="/ucms/write/:menuId/:id"
                  element={<ContentWrite />}
                />

                <Route path="/join">
                  <Route
                    path=""
                    element={<Navigate to="type" replace={true} />}
                  />
                  <Route path="type" element={<JoinTypePage />} />

                  <Route path="form">
                    <Route
                      path=""
                      element={<Navigate to="nor" replace={true} />}
                    />
                    <Route path="nor" element={<JoinFormPage />} />
                    <Route path="co" element={<JoinFormPage />} />
                  </Route>
                  <Route
                    path="emailAuth/:email"
                    element={<JoinEmailAuthPage />}
                  />
                </Route>

                {routes}

                <Route path="/my/alert" element={<AlertGridContainer />} />
                <Route path="/my/terms" element={<MyTermUpdatePage />} />

                <Route path="/terms/privacy" element={<PrivacyTermPage />} />

                <Route path="/test" element={<TestMain />}>
                  <Route path="editor" element={<BoardEditor />} />
                  <Route
                    path="editor2"
                    element={
                      <ContentEditor
                        data={
                          new EditorData({
                            title: '',
                            body: '테스트',
                          })
                        }
                      />
                    }
                  />
                  <Route path="bio" element={<BioTestPage />} />
                  <Route path="form" element={<FormTestPage />} />
                  <Route path="player" element={<PlayerTestPage />} />
                  <Route path="crypto" element={<CryptoTestPage />} />
                  <Route path="pin" element={<PinTestPage />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="/download" element={<DownloadPage />} />
        <Route path="/embed/:menuId/:id" element={<VideoEmbedPage />} />
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
    </BrowserRouter>
  );
}
