import { Outlet } from 'react-router-dom';
import useLogin from '@/components/user/login/useLogin';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import useMenu, { menuType } from '@/components/menu/useMenu';
import { useEffect, useMemo } from 'react';
import Config from '@/app/config/config';
import { myInfoMenuMap } from '@/app/config/myinfoMenuMap';

export default function MyAffltContainer({} = {}) {
  const navigate = useNavigate2();
  const { pathname } = useLocation2();
  const { isLoggedIn } = useLogin();
  const { isLoading, menuList, findMenu } = useMenu();

  const _childMenuList = useMemo(() => {
    return menuList
      .filter(
        (it) =>
          it.menuType === menuType.my &&
          it.useYN === 'Y' &&
          it.menuUrl !== '' &&
          it.menuLevel === 2 &&
          it.parentId == Config.myAffltId &&
          myInfoMenuMap[it.menuUrl] != null
      )
      .sort((a, b) => a.sortSeq - b.sortSeq);
  }, [menuList]);

  if (isLoggedIn === false) {
    window.location.href = '/';
    return '';
  }

  if (isLoading || _childMenuList == null) {
    return '';
  }

  // 선택된 메뉴가 없을 경우 첫 번째 메뉴로 이동
  useEffect(() => {
    const path = pathname.split('/').pop();
    if (path !== 'myafflt') return;

    navigate(_childMenuList[0].menuUrl, { replace: true });
  }, []);

  return (
    <div className="min-h-[100vh]">
      <Outlet />
    </div>
  );
}
