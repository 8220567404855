import { useDispatch, useSelector } from 'react-redux';
import {
  getFollowerList,
  getFollowList,
} from '@/components/user/login/LoginReducer';
import { useEffect, useMemo, useState } from 'react';
import MyPageApi from '@/components/user/my/data/MyPageApi';
import useLogin from '@/components/user/login/useLogin';
import LoginUtil from '@/components/user/login/LoginUtil';
import { NotLoggedInError } from '@/components/user/login/LoginApi';

export default function useSubscribe() {
  const dispatch = useDispatch();
  const { isLoggedIn } = useLogin();
  const followList = useSelector((state) => state.loginReducer.followList);
  const followerList = useSelector((state) => state.loginReducer.followerList);
  const [followingUpdateTime, setFollowingUpdateTime] = useState(null);

  const fetchFollowing = () => {
    dispatch(getFollowList({ size: 99999 }));
  };
  const fetchFollower = () => {
    dispatch(getFollowerList({ size: 99999 }));
  };

  const followData = useMemo(() => {
    if (followList.data != null) {
      setFollowingUpdateTime(new Date().getTime());
      return followList.data.data;
    }
  }, [followList]);

  const followerData = useMemo(() => {
    if (followerList.data != null) {
      return followerList.data.data;
    }
  }, [followerList]);

  useEffect(() => {
    if (isLoggedIn === true) {
      if (followerList.data == null) {
        fetchFollower();
      }
      if (followList.data == null) {
        fetchFollowing();
      }
    }
  }, [isLoggedIn]);

  const subscribe = async (userNo) => {
    if ((await LoginUtil.getSessionKeyWithUserId()) == null) {
      throw new NotLoggedInError();
    }
    try {
      const res = await MyPageApi.subscribe(userNo);
      if (res.code === '00' || res.code === '71') {
        return true;
      }
    } catch (e) {
      console.error(e);
    } finally {
      fetchFollowing();
    }
    throw new Error('구독 실패');
  };

  const unsubscribe = async (userNo, refresh = true) => {
    if ((await LoginUtil.getSessionKeyWithUserId()) == null) {
      throw new NotLoggedInError();
    }
    try {
      const res = await MyPageApi.unsubscribe(userNo);
      if (res.code === '00' || res.code === '72') {
        return false;
      }
      throw new Error(res.message);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      if (refresh) {
        fetchFollowing();
      }
    }
    throw new Error('구독 취소 실패');
  };

  const isSubscribed = (userNo) => {
    return followData?.find((it) => it.userNo === userNo) != null;
  };

  return {
    followingCount: followData?.length ?? 0,
    followerCount: followerData?.length ?? 0,
    subscribe,
    unsubscribe,
    isSubscribed,
    fetchFollowing,
    fetchFollower,
    updateTime: followingUpdateTime,
  };
}
