import { useState } from 'react';
import JoinApi, { UserType } from '@/components/join/JoinApi';
import useCompanyList from '@/components/user/company/useCompanyList';
import { useTimer } from '@/components/card/thumbnail/useCountDown';
import useDebugWeb from '@/components/debug/useDebugWeb';

export const EmailAuthStatus = {
  none: 'none',
  duplicate: 'duplicate',
  sent: 'sent',
  resent: 'resent',
  verified: 'verified',
  expired: 'expired',
  invalid_code: 'invalid_code',
  error: 'error',
};

export default function useJoinEmailAuth({ isCo }) {
  const { sendLog } = useDebugWeb();
  const companyList = useCompanyList({ excludeNormal: true });
  const getCompany = (email) => {
    return companyList.find((it) => email.includes(`@${it.compDomain}`));
  };

  const {
    remainTime: remainTime,
    restart: timerStart,
    clear: timerClear,
    isRunning: isTimerRunning,
  } = useTimer({ time: 10 * 60 * 1000 });

  const [state, setState] = useState({
    refId: null,
    authSentEmail: '',
    authState: EmailAuthStatus.none,
    isAuthSendLoading: false,
    isAuthValidLoading: false,
    showAuthInput: false,
  });

  const reqCode = async ({ refId, email }) => {
    // TODO 이메일 형식, 중복 검증
    if (state.authState === EmailAuthStatus.verified) return;
    if (isTimerRunning) {
      // 타이머가 도는 중이면
      if (state.authSentEmail === email) {
        // 메일 주소가 바뀌지 않았으면
        throw new EmailAuthError(
          '이미 인증 메일이 발송되었습니다. 잠시 기다려주세요'
        );
      }
    }
    let e = email.split('@');
    let company = getCompany(email);
    let userType = isCo ? UserType.employee : UserType.normal;
    let compId = isCo ? company?.compId : 0;
    if (compId == null) {
      // throw new EmailAuthError('알 수 없는 임직원 메일 입니다.');
    }

    // 타이머가 돌지 않았거나, 메일 주소가 바뀌었으면
    let res;
    let error;
    let param = {
      refId: refId ?? state.refId,
      emailId: e[0],
      emailDomain: e[1],
      userType,
      compId,
    };
    try {
      setState((s) => ({
        ...s,
        isAuthSendLoading: true,
      }));
      res = await JoinApi.joinEmailCodeReq(param);
    } catch (e) {
      console.error(e);
      error = e.message;
      res = e.response?.data;
    } finally {
      setState((s) => ({
        ...s,
        isAuthSendLoading: false,
      }));
    }

    // sendLog(
    //   {
    //     type: 'signup_email_req_code',
    //     req: param,
    //     res: res,
    //     error: error,
    //   },
    //   state.refId
    // );

    if (error != null) {
      throw new EmailAuthError(
        '인증 메일 발송에 실패하였습니다. 잠시 후 다시 시도해주세요'
      );
    }

    if (res.code !== '00' || res.id == null) {
      setState((s) => ({
        ...s,
        showAuthInput: false,
        authSentEmail: '',
        authState: EmailAuthStatus.none,
      }));
      if (res.code === '95' || res.code === '96') {
        throw new EmailAuthError('이미 가입된 이메일 입니다.');
      } else {
        throw new EmailAuthError(
          '알 수 없는 오류입니다. 잠시 후 다시 시도해주세요.'
        );
      }
    }

    setState((s) => ({
      ...s,
      refId: res.id,
      showAuthInput: true,
      authSentEmail: email,
      authState: EmailAuthStatus.sent,
    }));
    timerStart();
  };

  const reqAuth = async ({ refId, email, authCode }) => {
    if (email !== state.authSentEmail) {
      throw new EmailAuthError(
        '이메일이 변경되었습니다. 인증 번호 발송을 다시해주세요.'
      );
    }
    let res;
    let error;
    let param = {
      refId: state.refId,
      authNo: authCode,
    };
    try {
      setState((s) => ({
        ...s,
        isAuthValidLoading: true,
      }));
      res = await JoinApi.joinEmailCodeAuth(param);
    } catch (e) {
      console.error(e);
      error = e.message;
      res = e.response?.data;
    } finally {
      setState((s) => ({
        ...s,
        isAuthValidLoading: false,
      }));
    }

    sendLog(
      {
        type: 'signup_email_auth_code',
        req: param,
        res: res,
        error: error,
      },
      state.refId
    );

    if (error != null) {
      throw new EmailAuthError('인증번호 확인에 실패했습니다.');
    }

    if (res.code !== '00') {
      if (res.code === '98') {
        throw new EmailAuthError('인증번호가 올바르지 않습니다.');
      } else {
        throw new EmailAuthError('인증번호 확인에 실패했습니다.');
      }
    }
    setState((s) => ({
      ...s,
      authState: EmailAuthStatus.verified,
      isAuthValidLoading: false,
      showAuthInput: false,
    }));
    timerClear();
    return true;
  };

  return {
    isAuthSendLoading: state.isAuthSendLoading,
    isAuthValidLoading: state.isAuthValidLoading,
    authState: state.authState,
    showAuthInput: state.showAuthInput,
    refId: state.refId,
    remainTime: remainTime,
    reqCode,
    reqAuth,
  };
}

export class EmailAuthError {
  constructor(message) {
    this.message = message;
  }
}
