import { useEffect, useMemo, useRef, useState } from 'react';
import useLogin from '@/components/user/login/useLogin';
import useAESCrypto from '@/components/user/useAESCrypto';
import { closeWonderingSideBar } from '@/components/sidebar/wonderingReducer';
import { useDispatch, useSelector } from 'react-redux';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import { openSideBar } from '@/components/sidebar/sidebarReducer';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import { useSearchParams } from 'react-router-dom';
import useDebugWeb from '@/components/debug/useDebugWeb';

export default function WonderingIframe({ className, src }) {
  const { sendLog } = useDebugWeb();
  const { state: _state, query } = useLocation2();
  const [_, setSearchParams] = useSearchParams();
  const isOpen = useSelector((state) => state.wonderingReducer.isOpen);
  const dispatch = useDispatch();
  const navigate = useNavigate2();
  const ref = useRef();
  const { data } = useLogin();
  const { encrypt } = useAESCrypto({
    key: 'Wondering!@#',
  });

  const wonderingState = useMemo(() => {
    let page = _state?.wondering ?? query.wondering ?? '';
    if (page === true || page === 'true') {
      // 예전 기능으로 true 가 오는 경우가 있음.
      page = '';
    }
    return page;
  }, []);

  useEffect(() => {
    // sendLog({
    //   type: 'wondering_iframe',
    //   wonderingMenu: wonderingState,
    // });
  }, []);

  useEffect(() => {
    // wondering queryparam 제거
    setSearchParams(
      (prev) => {
        let res = {
          ...Object.fromEntries(prev),
        };

        delete res.wondering;
        return res;
      },
      { replace: true }
    );
  }, []);

  const encryptedEmpNo = useMemo(() => {
    if (data?.wbnEmpNo == null) {
      return null;
    } else {
      return encrypt(data.wbnEmpNo);
    }
  }, [data]);

  const [state, setState] = useState({
    isLoading: false,
    rendered: false,
  });

  const pageUrl = useMemo(() => {
    return `${src}${wonderingState}`;
  }, [src]);

  const login = async (encodedEmpNo) => {
    // 로그인 못해도 넘어감
    console.log('fallback go');
    go();
  };

  // 두번 go 불가능
  const go = () => {
    console.log('iframe go');
    if (state.rendered) {
      return;
    }
    setState((p) => ({
      ...p,
      rendered: true,
    }));
    ref.current.src = pageUrl;
    ref.current.addEventListener('load', (e) => {
      onIframeLoad(e);
    });
    console.log('iframe go end');
  };

  // iframe 로딩 완료 시 이미지 숨김
  const onIframeLoad = (e) => {
    console.log('onIframeLoad', e, e.target, e.target.src);
    if (e.target.src === '') {
      return;
    }
    setState((p) => ({
      ...p,
      isLoading: false,
    }));
  };

  useEffect(() => {
    if (src == null || src === '') {
      if (isOpen) {
        dispatch(closeWonderingSideBar());
      } else {
        navigate(-1);
      }
    }
  }, [src]);

  useEffect(() => {
    if (encryptedEmpNo != null) {
      login(encryptedEmpNo);
    } else {
      dispatch(openSideBar());
      if (isOpen) {
        dispatch(closeWonderingSideBar());
      } else {
        navigate(-1);
      }
    }
  }, [encryptedEmpNo]);

  return (
    <>
      <iframe
        ref={ref}
        className={className}
        id="wonderingIframe"
        key="wonderingIframe"
        name="wonderingIframe"
      />
    </>
  );
}
