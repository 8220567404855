import {
  createPromiseThunk,
  handleAsyncActions,
  reducerUtils,
} from '../../../lib/asyncUtils';
import LoginApi from './LoginApi';
import LoginUtil from './LoginUtil';
import CompanyApi from '../company/CompanyApi';
import MyPageApi from '@/components/user/my/data/MyPageApi';

const API_ACTION = {
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGIN_ERROR: 'USER_LOGIN_ERROR',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',

  COMPANY_LIST: 'COMPANY_LIST',
  COMPANY_LIST_ERROR: 'COMPANY_LIST_ERROR',
  COMPANY_LIST_SUCCESS: 'COMPANY_LIST_SUCCESS',

  FOLLOW_LIST: 'FOLLOW_LIST',
  FOLLOW_LIST_SUCCESS: 'FOLLOW_LIST_SUCCESS',
  FOLLOW_LIST_ERROR: 'FOLLOW_LIST_ERROR',

  FOLLOWER_LIST: 'FOLLOWER_LIST',
  FOLLOWER_LIST_SUCCESS: 'FOLLOWER_LIST_SUCCESS',
  FOLLOWER_LIST_ERROR: 'FOLLOWER_LIST_ERROR',

  ALERT_COUNT: 'ALERT_COUNT',
  ALERT_COUNT_SUCCESS: 'ALERT_COUNT_SUCCESS',
  ALERT_COUNT_ERROR: 'ALERT_COUNT_ERROR',

  SESSION_CHECK: 'SESSION_CHECK',
  SESSION_CHECK_SUCCESS: 'SESSION_CHECK_SUCCESS',
  SESSION_CHECK_ERROR: 'SESSION_CHECK_ERROR',
};

Object.freeze(API_ACTION);

const ACTION = {
  USER_LOGOUT: 'USER_LOGOUT',
  SESSION_LOGIN_FAIL: 'SESSION_LOGIN_FAIL',
  SET_LOGIN_EXPIRED: 'SET_LOGIN_EXPIRED',
};

Object.freeze(ACTION);

export const getCompanyList = createPromiseThunk(
  API_ACTION.COMPANY_LIST,
  CompanyApi.getCompanyList
);
export const userLogin = createPromiseThunk(
  API_ACTION.USER_LOGIN,
  LoginApi.login
);

export const setSessionLoginFail = () => ({ type: ACTION.SESSION_LOGIN_FAIL });

export const setUserLogin = (data) => ({
  type: API_ACTION.USER_LOGIN_SUCCESS,
  payload: data,
});
export const userLogout = () => ({ type: ACTION.USER_LOGOUT });
export const getFollowList = createPromiseThunk(
  API_ACTION.FOLLOW_LIST,
  MyPageApi.followingList
);
export const getFollowerList = createPromiseThunk(
  API_ACTION.FOLLOWER_LIST,
  MyPageApi.followerList
);

export const getAlertCount = createPromiseThunk(
  API_ACTION.ALERT_COUNT,
  MyPageApi.alertList
);

export const setShowLoginExpired = (value) => ({
  type: ACTION.SET_LOGIN_EXPIRED,
  payload: value,
});

const initialState = {
  loginResult: reducerUtils.initial(),
  companyList: reducerUtils.initial(),
  followList: reducerUtils.initial(),
  followerList: reducerUtils.initial(),
  alertCount: reducerUtils.initial(),
  sessionLoading: null,
  isSessionLogin: false,
  showLoginExpired: false,
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case API_ACTION.USER_LOGIN:
    case API_ACTION.USER_LOGIN_ERROR:
    case API_ACTION.USER_LOGIN_SUCCESS:
      return {
        ...handleAsyncActions(API_ACTION.USER_LOGIN, 'loginResult')(
          state,
          action
        ),
        isSessionLogin: false,
      };

    case API_ACTION.COMPANY_LIST:
    case API_ACTION.COMPANY_LIST_ERROR:
    case API_ACTION.COMPANY_LIST_SUCCESS:
      return handleAsyncActions(API_ACTION.COMPANY_LIST, 'companyList')(
        state,
        action
      );

    case API_ACTION.FOLLOW_LIST:
    case API_ACTION.FOLLOW_LIST_SUCCESS:
    case API_ACTION.FOLLOW_LIST_ERROR:
      return handleAsyncActions(API_ACTION.FOLLOW_LIST, 'followList')(
        state,
        action
      );

    case API_ACTION.FOLLOWER_LIST:
    case API_ACTION.FOLLOWER_LIST_SUCCESS:
    case API_ACTION.FOLLOWER_LIST_ERROR:
      return handleAsyncActions(API_ACTION.FOLLOWER_LIST, 'followerList')(
        state,
        action
      );

    case API_ACTION.ALERT_COUNT:
    case API_ACTION.ALERT_COUNT_SUCCESS:
    case API_ACTION.ALERT_COUNT_ERROR:
      return handleAsyncActions(API_ACTION.ALERT_COUNT, 'alertCount')(
        state,
        action
      );

    case API_ACTION.SESSION_CHECK:
    case API_ACTION.SESSION_CHECK_SUCCESS:
    case API_ACTION.SESSION_CHECK_ERROR:
      return {
        ...state,
        sessionLoading: action.type === API_ACTION.SESSION_CHECK,
        isSessionLogin: true,
      };

    case ACTION.USER_LOGOUT: {
      // TODO 홈으로?
      LoginUtil.clearLoginInfo();
      return {
        ...state,
        loginResult: reducerUtils.initial(),
        followList: reducerUtils.initial(),
        followerList: reducerUtils.initial(),
        alertCount: reducerUtils.initial(),
      };
    }

    case ACTION.SESSION_LOGIN_FAIL: {
      return {
        ...state,
        loginResult: reducerUtils.initial(),
      };
    }

    case ACTION.SET_LOGIN_EXPIRED: {
      return {
        ...state,
        showLoginExpired: action.payload,
      };
    }

    default:
      return state;
  }
}
