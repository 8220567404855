import { useState } from 'react';
import MyPageApi from '@/components/user/my/data/MyPageApi';

export default function useEduVideos() {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(null);
  const [page, setPage] = useState(1);

  const load = async ({ page = 1, size = 9, menuId } = {}) => {
    if (menuId === 'null' || menuId === 'undefined' || menuId == null) return;

    setPage(page);
    setIsLoading(true);
    let res = await MyPageApi.getEducationContents({ page, size, menuId });
    setList(res.data);
    setTotal(res.data?.[0]?._itemTotal ?? 0);
    setIsLoading(false);
    return res.data;
  };

  return {
    isLoading,
    list,
    total,
    load,
    page,
  };
}
