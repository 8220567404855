import './App.css';
import AppRoute from './config/Route';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import useWindowDimensions from '@/lib/useWindowDimensions';
import { getEduMenuList, getMenuList } from '@/components/menu/MenuReducer';
import useMenu from '@/components/menu/useMenu';
import { CircularProgress } from '@mui/material';
import useLogin from '@/components/user/login/useLogin';
import { ReactQueryDevtools } from 'react-query/devtools';
import { createBrowserHistory } from 'history';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'dayjs/locale/ko';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { bioCanAuth } from '@/components/easyLogin/bio/BioReducer';

export const history = createBrowserHistory();

function App() {
  const dispatch = useDispatch();
  const { isLoading, menuList, rootMenuList } = useMenu();
  const { isLoggedIn } = useLogin();
  const { setIsWebView } = useWindowDimensions();
  const [isWebViewInit, setWebViewInit] = useState(false);
  const [test, setTest] = useState(null);
  window.addEventListener('flutterInAppWebViewPlatformReady', function (event) {
    console.log('flutterInAppWebViewPlatformReady');
    if (!isWebViewInit) {
      setWebViewInit(true);
    }
  });

  const _isLoading = useMemo(() => {
    if (isLoading) {
      return true;
    } else {
      if (window.isWebViewApp) {
        return !isWebViewInit;
      } else {
        return false;
      }
    }
  }, [isLoading, isWebViewInit]);

  const goToLanding = () => {
    // console.log("goToLanding", getIsLanding(), window.location.pathname);
    // if(getIsLanding() == null && (window.location.pathname == "/" || window.location.pathname == "")) {
    //     window.location.href = "/landing";
    // return true;
    // }
    return false;
  };

  useEffect(() => {
    if (!goToLanding()) {
      dispatch(getMenuList(isLoggedIn));
      dispatch(getEduMenuList());
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setIsWebView(isWebViewInit);
    if (isWebViewInit) {
      localStorage.setItem('isDev', 'true');
      setTest(new Date().getTime());
      console.log('webAppInitialized');
      dispatch(bioCanAuth());
    }
  }, [isWebViewInit]);

  goToLanding();

  if (_isLoading && menuList == null) {
    // FIXME isLoading만으로 이탈 불가능한 문제
    return (
      <div className="h-[100vh] w-full flex items-center justify-center">
        <CircularProgress className="loading" />
      </div>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ko'}>
      <>
        {test && false && <div />}
        <ReactQueryDevtools initialIsOpen={false} />
        <AppRoute />
      </>
    </LocalizationProvider>
  );
}

export default App;
