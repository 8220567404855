import '../../components/ucms/chat/common/chatCustom.css';
import VideoInfoItem from '../../components/ucms/video/VideoInfoItem';
import { CircularProgress, styled, Tab, Tabs } from '@mui/material';
import { createRef, useEffect, useMemo, useRef, useState } from 'react';
import useWindowDimensions from '../../lib/useWindowDimensions';
import TabPanel from '../../components/ucms/video/TabPanel';
import '../../components/ucms/video/TabCustom.css';
import ShareModal from '../../components/modal/share/ShareModal';
import UcmsMobileHeader from '@/components/ucms/common/UcmsMobileHeader';
import { WooriPlayerOption } from '@/components/player/WooriPlayer';
import { useDispatch } from 'react-redux';
import { play, shouldDisposePlayer } from '@/components/player/playerReducer';
import AutoBreadCrumb from '@/components/ucms/common/AutoBreadCrumb';
import ContentApi, { ContentFileType } from '@/components/content/ContentApi';
import useNavigate2 from '@/components/common/transition/useNavigate2';
import FileView from '@/components/ucms/common/FileView';
import useMenu from '@/components/menu/useMenu';
import useLocation2 from '@/components/sidebar/hook/useLocation2';
import { unstable_useBlocker, useSearchParams } from 'react-router-dom';
import RelatedVideoList from '@/components/ucms/video/RelatedVideoList';
import useLiveBanner from '@/components/content/useLiveBanner';
import { VideoChat } from '@/components/ucms/chat/common/VideoChat';
import useLogin from '@/components/user/login/useLogin';
import useMobileNavigation from '@/components/navigation/useMobileNavigation';
import classNames from 'classnames';
import { ContentType } from '@/app/config/ContentType';
import CommentListWrap from '@/components/comment/CommentListWrap';
import { pop } from '@/components/ucms/detailHistoryReducer';
import { history } from '@/app/App';

/**
 * @param {number} index
 * @return {{"aria-controls": string, id: string}}
 */
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '80%',
    backgroundColor: '#008cdc',
  },
});

export default function UcmsLMSVideoDetailPage({
  isInitLoading,
  isLoading,
  data,
  content,
  eduInfo,
  videoFile,
  userThumbnails,
  thumbnails,
  files,
  replies,
  load,
  live,
  attachments,
  onLikeClick,
  onWriteComment,
  onDelete,
  onEdit,
  onReport,
  onShared,
  relatedContents,
  onAuthorSubscribedChange,
  viewTime,
  updateViewTime,
  password,
  pollingCount,
  isLivePolling,
  dragLockYn,
}) {
  const { isShow: isMobileNavigationShow } = useMobileNavigation();
  const { isLoggedIn } = useLogin();
  const { findMenu } = useMenu();
  const { query, pathname } = useLocation2();
  const { lastSec: _lastSec } = useMemo(() => query, [query]);
  const lastSec = useMemo(() => Number(_lastSec ?? '0'), [_lastSec]);
  const navigator = useNavigate2();
  const dispatch = useDispatch();
  const [_, setSearchParams] = useSearchParams();
  // const isPip = useSelector(state => state.playerReducer.isPip);
  // const playerOption = useSelector(state => state.playerReducer.option);
  // const useChat = useMemo(() => content?.chatYn === "Y" && isLoggedIn && content?.liveYn === "Y", [content, isLoggedIn]);
  const useChat = useMemo(() => content?.chatYn === 'Y', [content]);
  // const isLive = useMemo(() => content?.liveYn === "Y", [content]);
  const { isLoading: isLiveBannerLoading, noticeEvent } = useLiveBanner({
    cid: content?.cid,
  });

  const [tab, setTab] = useState(0);

  const tabRefs = [createRef(), createRef(), createRef()];
  const tabContentRefs = [createRef(), createRef(), createRef()];
  const chatAndRelVideoAreaRef = createRef();
  const videoAndInfoAreaRef = createRef();
  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    const isTabExist = tabRefs.find(
      (ref) => ref.current?.getElementsByClassName('tab_content').length !== 0
    );
    // console.log(isTabExist);

    // 모바일
    if (isMobile) {
      if (!isTabExist) {
        console.log('tab not exist. appending');
        tabRefs.forEach((ref, i) => {
          if (tabContentRefs[i].current != null) {
            ref.current?.append(tabContentRefs[i].current);
          }
        });
      }
    } else {
      if (isTabExist) {
        if (tabContentRefs[0].current != null) {
          videoAndInfoAreaRef.current?.append(tabContentRefs[0].current);
        }
        if (tabContentRefs[1].current != null) {
          chatAndRelVideoAreaRef.current?.append(tabContentRefs[1].current);
        }
        if (tabContentRefs[2].current != null) {
          chatAndRelVideoAreaRef.current?.append(tabContentRefs[2].current);
        }
      }
    }
  }, [isMobile]);

  const [shareModalVisible, setShareModalVisible] = useState(false);

  const menuName = useMemo(() => {
    return findMenu(data?.menuId); // 권한 없는 메뉴도 비공개 글인 경우 접근 가능
  }, [data]);

  // liveSt 체크 해 최초로딩이 아닌 경우에도 업데이트
  // useContentInfo에서 LiveSt 폴링
  useEffect(() => {
    if (!isInitLoading && !isLiveBannerLoading) {
      // if (!videoFile) return;
      let poster = null;
      // 라이브이거나 오디오인 경우에만 포스터 설정
      if (
        data.liveYn === 'Y' ||
        videoFile?.fileType === ContentFileType.audio
      ) {
        if (data.directPosterFile != null && data.directPosterFile !== '') {
          poster = `${data.directThumbUrl}${data.directPosterFile}`;
        } else if (data.userPosterFile != null && data.userPosterFile !== '') {
          poster = `${data.userThumbUrl}${data.userPosterFile}`;
        }
      }

      // console.log(pollingCount, "th polling");
      if (!isInitLoading) {
        if (data.liveYn === 'Y') {
          const option = new WooriPlayerOption({
            title: content.title,
            author: content.author,
            cid: content.cid,
            cdnSt: live?.liveSt,
            menuId: data.menuId,
            qualities: live?.qualities,
            audioPosterMode: data.contentType === ContentType.AL,
            poster: poster,
            qualityDefault: live?.qualities?.[0]?.label,
            thumbnail:
              userThumbnails?.[0]?.thumbnailUrl ??
              thumbnails?.[0]?.thumbnailUrl,
            spriteThumbnail: live?.spriteThumbnail,
            liveMode: true,
            liveStartTime: live.liveStartDt,
            timeUpdateCallback: function (e, force) {
              updateViewTime(e, force);
            },
            isLivePolling,
            noticeEvent, // live만 배너 사용
          });
          dispatch(play(option));

          if (window.WooriPlayer.instance) {
            try {
              let _player = window.WooriPlayer.instance?.wooriPlayer.player;

              if (!_player.paused()) {
                ContentApi.userLiveViewTimeUpdate({
                  cid: content.cid,
                  eduId: eduInfo?.eduId ?? -1,
                });
              }
            } catch (e) {
              navigator('/');
            }
          }
        } else {
          // 교육 대상자가 아닌 경우, eduInfo.completionYn null
          // begin, end 는 교육 대상자가 아닌 경우나, 지정일이 없으면 null 일 수 있읍
          const begin = new Date(eduInfo?.beginDt ?? 0).getTime();
          const end = new Date(eduInfo?.endDt ?? 8640000000000000).getTime();
          const now = new Date().getTime();
          const canSkip = !(
            begin <= now &&
            now <= end &&
            eduInfo?.completionYn === 'N'
          );

          const option = new WooriPlayerOption({
            title: content.title,
            author: content.author,
            cid: content.cid,
            cdnSt: videoFile?.cdnSt,
            menuId: data.menuId,
            startPosition: viewTime?.lastSec ?? 0,
            audioPosterMode: videoFile?.fileType === ContentFileType.audio,
            poster: poster,
            qualities: videoFile?.qualities,
            qualityDefault: videoFile?.qualities?.[0]?.label,
            thumbnail:
              userThumbnails?.[0]?.thumbnailUrl ??
              thumbnails?.[0]?.thumbnailUrl,
            spriteThumbnail: videoFile?.spriteThumbnail,
            liveMode: false,
            timeUpdateCallback: function (e, force) {
              updateViewTime(e, force);
            },
            nextContents: {
              use: content.nextVideo != null,
              content: {
                ...content.nextVideo,
                clickCallback: function (e) {
                  // console.log(`클릭! 다음영상 재생`,);
                  let menuId = content.nextVideo.menuId;
                  let cid = content.nextVideo.cid;
                  navigator(`/ucms/view/${menuId}/${cid}`);
                },
                cancelCallback: function (e) {
                  console.log('nextContentClosed default');
                },
              },
            },
            canSkip: canSkip,
          });
          dispatch(play(option));

          // 시간 쿼리 있으면  새로고침시 머무는문제 있어서, 바로 제거,
          if (lastSec > 0) {
            let q = {
              ...query,
            };
            delete q.lastSec;
            setSearchParams(q, { replace: true });
          }
        }
      }
    }
  }, [isInitLoading, isLiveBannerLoading, pollingCount]);

  useEffect(() => {
    return () => {
      // console.log("unmounting", isPip, playerOption);
      // if (!isPip) {
      dispatch(shouldDisposePlayer());
      // }
    };
  }, []);

  const onCommentSubmit = async (comment, replyId) => {
    return await onWriteComment(data.cid, comment, replyId);
  };

  const commentListRef = useRef();

  const shareModalData = useMemo(() => {
    return {
      ...content,
      thumbnailUrl:
        userThumbnails?.[0]?.thumbnailUrl ?? thumbnails?.[0]?.thumbnailUrl,
      link: window.location.href,
      profileName: '채널W',
      profileThumbnailUrl: window.location.origin + '/images/logo.png',
    };
  }, [content]);

  if (isInitLoading || content == null) {
    return (
      <div className="w-full flex items-center justify-center">
        <CircularProgress className="loading" />
      </div>
    );
  }

  return (
    <div id="contents" className="broad_wrap">
      {(isLiveBannerLoading || isLoading) && (
        <div
          className="w-full flex items-center justify-center fixed"
          style={{
            backgroundColor: '#c3c3c369',
            'z-index': 9999,
            height: '100%',
            top: 0,
          }}
        >
          <CircularProgress className="loading" />
        </div>
      )}
      <ShareModal
        data={shareModalData}
        onShared={onShared}
        useVideoEmbed={true}
        visible={shareModalVisible}
        onVisibilityChanged={setShareModalVisible}
      />
      {isMobile && <UcmsMobileHeader title={menuName?.menuNm} />}
      <div className="inner">
        <div className="grid lg:grid-cols-3 lg:mt-16">
          <div className="lg:col-span-2 lg:mr-8">
            <div
              className={classNames({
                'broad_thumb relative w-full': true,
                no_nav: !isMobileNavigationShow,
              })}
              ref={videoAndInfoAreaRef}
            >
              <AutoBreadCrumb menuId={data.menuId} />

              <div className="broad_thumb_wrap w-full">
                <div className="broad_thumb_box">
                  <div className="player_portal" />
                </div>
                <div className="player_placeholder" />

                <div className="watch_content">
                  <div
                    className="py-8 pb-10 tab_content px-4 sm:px-6 md:px-0"
                    id="tab1"
                    ref={tabContentRefs[0]}
                  >
                    <VideoInfoItem
                      headerText={eduInfo?.eduGrpNm}
                      video={content}
                      curViewCnt={live?.curViewCnt}
                      onShareVideo={() => setShareModalVisible(true)}
                      onDelete={onDelete}
                      onEdit={onEdit}
                      onReport={onReport}
                      onLikeClick={onLikeClick}
                      dragLockYn={dragLockYn}
                      onAuthorSubscribedChange={onAuthorSubscribedChange}
                    />
                    {attachments.length > 0 && (
                      <FileView files={attachments} isAuthor={false} />
                    )}
                    <CommentListWrap
                      ref={commentListRef}
                      cid={data.cid}
                      totalReply={data.replyCnt}
                      onCommentSubmit={onCommentSubmit}
                    />
                  </div>
                </div>
              </div>
              {isMobile && (
                <div>
                  <StyledTabs
                    value={tab}
                    onChange={(_, value) => setTab(value)}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    style={{ background: 'white' }}
                  >
                    <Tab label="기본정보" {...a11yProps(0)} />
                    <Tab
                      className={useChat ? '' : 'hidden'}
                      label="채팅"
                      {...a11yProps(1)}
                    />
                    <Tab label="관련영상" {...a11yProps(2)} />
                  </StyledTabs>
                </div>
              )}
              <TabPanel
                value={tab}
                index={0}
                data-tab={1}
                ref={tabRefs[0]}
                className="overflow-y-auto"
              />
              <TabPanel
                value={tab}
                index={1}
                data-tab={2}
                ref={tabRefs[1]}
                className="overflow-y-auto chat_tab"
              />
              <TabPanel
                value={tab}
                index={2}
                data-tab={3}
                ref={tabRefs[2]}
                className="overflow-y-auto"
              />
            </div>
          </div>

          <div className="chat_box_wrap mt-0" ref={chatAndRelVideoAreaRef}>
            {useChat && (
              <VideoChat
                id="tab2"
                ref={tabContentRefs[1]}
                isLive={content?.liveYn === 'Y'}
                cid={data.cid}
              />
            )}
            <RelatedVideoList
              ref={tabContentRefs[2]}
              menuId={data.menuId}
              cid={data.cid}
              contentType={data.contentType}
              pw={password}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
