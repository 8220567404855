import { useState } from 'react';
import MyPageApi from '@/components/user/my/data/MyPageApi';

export default function useAlertList({ initialPage }) {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(null);
  const [page, setPage] = useState(initialPage);
  const [unreadCount, setUnreadCount] = useState(0);

  const load = async ({ page = 1, size = 9 } = {}) => {
    setPage(page);
    setIsLoading(true);
    let res = await MyPageApi.alertList({
      _pageNo: page,
      _pageSize: size,
    });
    setList(res.data);
    setTotal(res.total ?? 0);
    setUnreadCount(res.total_new ?? 0);
    setIsLoading(false);
    return res.data;
  };

  const remove = async (id) => {
    setIsLoading(true);
    await MyPageApi.alertDelete(id);
    setIsLoading(false);
    return true;
  };

  const makeRead = async (id) => {
    await MyPageApi.alertViewUpdate(id);
    return true;
  };

  console.log(page);

  return {
    isLoading,
    list,
    unreadCount,
    makeRead,
    total,
    load,
    page,
    remove,
  };
}
