import ThumbnailCard from '../../../components/card/ThumbnailCard';
import useLikeVODList from '@/components/user/my/useLikeVODList';
import { useEffect, useMemo, useState } from 'react';
import PageIndicator from '@/components/content/paging/PageIndicator';
import { CircularProgress } from '@mui/material';
import { SearchApi } from '@/components/search/SearchApi';
import MyPageHeader from '@/page/my/MypageHeader';
import { useMyPagePaging } from '@/page/my/tabs/useMyPagePaging';

export default function LikeVODList() {
  const [isSelectMode, setSelectMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { isLoading, list, load, remove, total, page } = useLikeVODList();

  const { setPage } = useMyPagePaging({ loader: load, page });

  const dataList = useMemo(() => {
    return list?.map((it) => SearchApi.infoToThumbnailOption(it, false, true));
  }, [list]);

  return (
    <>
      <MyPageHeader
        option={{
          title: '좋아요한 영상',
          count: total,
          selectCount: selectedItems.length,
          isDeleteMode: isSelectMode,
          onEnterDeleteMode: () => setSelectMode(true),
          onCancel: () => {
            setSelectMode(false);
            setSelectedItems([]);
          },
          onAllSelect: () => setSelectedItems(dataList.map((it) => it.cid)),
          onDelete: async () => {
            if (selectedItems.length === 0) {
              return;
            }
            await remove(selectedItems);
            setSelectMode(false);
            setSelectedItems([]);
            load();
          },
        }}
      />
      {isLoading && (
        <div className="w-full flex items-center justify-center min-h-[400px]">
          <CircularProgress className="loading" />
        </div>
      )}
      {!isLoading && (dataList == null || dataList?.length === 0) && (
        <div className="w-full flex items-center justify-center min-h-[400px]">
          <span>좋아요한 영상이 없습니다.</span>
        </div>
      )}
      <div className="box_wrap grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:gap-4 sm:gap-y-12 gap-y-12">
        {!isLoading &&
          dataList.map((item, index) => (
            <div key={`thumbnail_card_${index}`} className="col">
              <ThumbnailCard
                option={{
                  ...item,
                  id: item.cid,
                  isSelectMode: isSelectMode,
                  author: item.category,
                  isSelected: selectedItems.includes(item.cid),
                  showCounter: false,
                  onSelectChange: (selected) => {
                    if (selected) {
                      setSelectedItems([...selectedItems, item.cid]);
                    } else {
                      setSelectedItems(
                        selectedItems.filter((it) => it !== item.cid)
                      );
                    }
                  },
                }}
              />
            </div>
          ))}
      </div>

      {total > 0 && (
        <PageIndicator
          option={{
            page: page - 1,
            totalCount: total,
            pageSize: 9,
            onPageChange: setPage,
          }}
        />
      )}
    </>
  );
}
